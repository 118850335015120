<template>
    <v-dialog
        v-model="modal"
        min-width="1080"
        persistent
    >
        <v-card v-if="questionnaire">
            <v-card-title class="text-h5 grey lighten-2 justify-content-between" v-if="voyageur">
                Voyage créé par {{ voyageur.civilite + ' ' + voyageur.prenom + ' ' +  voyageur.nom }}
            </v-card-title>

            <v-card-text class="mt-2">
                <v-row>
                    <v-col cols="6">
                        <v-card>
                            <v-card-title>
                                Informations des voyageurs
                            </v-card-title>

                            <v-card-text v-if="voyageur">
                                <v-row>
                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Email:</v-list-item-title>
                                                <v-list-item-subtitle>{{ voyageur.e_mail }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Adresse:</v-list-item-title>
                                                <v-list-item-subtitle>{{ voyageur.addresse }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Pays:</v-list-item-title>
                                                <v-list-item-subtitle>{{ voyageur.pays }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Ville:</v-list-item-title>
                                                <v-list-item-subtitle>{{ voyageur.ville }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Téléphone 1:</v-list-item-title>
                                                <v-list-item-subtitle>{{ voyageur.phone }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Téléphone 2:</v-list-item-title>
                                                <v-list-item-subtitle>{{ voyageur.phone_1 }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="6">
                        <v-card>
                            <v-card-title>
                                Informations de voyages
                            </v-card-title>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Voyage preféré:</v-list-item-title>
                                                <v-list-item-subtitle>{{ questionnaire.voyage_prefere }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Thématique preférée:</v-list-item-title>
                                                <v-list-item-subtitle>{{ questionnaire.thematique_preferer }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Durée de séjour:</v-list-item-title>
                                                <v-list-item-subtitle>{{ questionnaire.nombre_de_jour + 'jour' + ((parseInt(questionnaire.nombre_de_jour) > 1) ? 's' : '') }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Région:</v-list-item-title>
                                                <v-list-item-subtitle>{{ questionnaire.continent.continent_name }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Ville de départ:</v-list-item-title>
                                                <v-list-item-subtitle>{{ questionnaire.ville_de_depart }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Ville d'arrivée:</v-list-item-title>
                                                <v-list-item-subtitle>{{ questionnaire.ville_arriver }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Hôtel:</v-list-item-title>
                                                <v-list-item-subtitle>{{ questionnaire.hotel }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Budget:</v-list-item-title>
                                                <v-list-item-subtitle>{{ questionnaire.budet_min }} - {{ questionnaire.budet_max }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>Pension:</v-list-item-title>
                                                <v-list-item-subtitle>{{ questionnaire.pension }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    class="mr-2"
                    color="primary"
                    @click="close()"
                >
                    Fermer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Axios from 'axios';

    export default {
        name: 'modal-questionnaire',
        props: {
            questionnaire: Object,
            modal: Boolean
        },

        data: () => ({
            voyageur: null
        }),

        watch: {
            questionnaire: {
                handler(val){
                    if(val){
                        this.voyageur = val.voyageur
                    }
                },
                immediate: true
            }
        },

        methods: {
            close(){
                this.$emit('close');
            },
        }
    }
</script>

