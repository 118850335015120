<template>
    <v-app>
        <v-card-title>
            Gestion pourcentage Noah Voyages
        </v-card-title>

        <v-card flat class="mt-2">
            <v-overlay
                :value="loading"
            >
                <v-progress-circular
                    indeterminate
                    color="white"
                    :size="50"
                ></v-progress-circular>
            </v-overlay>
            <v-card-text>
                <v-form @submit.prevent="save">
                    <v-row>
                        <v-col cols="2">
                            <v-card flat>
                                <v-card-title>
                                    Chez les habitants
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field type="number" step="0.1" outlined dense v-model="model.chez" :rules="rules"></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="2">
                            <v-card flat>
                                <v-card-title>
                                    Deux étoiles
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field type="number" step="0.1" outlined dense v-model="model.deux" :rules="rules"></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="2">
                            <v-card flat>
                                <v-card-title>
                                    Trois étoiles
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field type="number" step="0.1" outlined dense v-model="model.trois" :rules="rules"></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="2">
                            <v-card flat>
                                <v-card-title>
                                    Quatre étoiles
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field type="number" step="0.1" outlined dense v-model="model.quatre" :rules="rules"></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="2">
                            <v-card flat>
                                <v-card-title>
                                    Cinq étoiles
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field type="number" step="0.1" outlined dense v-model="model.cinq" :rules="rules"></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="2">
                            <v-card flat>
                                <v-card-title>
                                    Mixtes
                                </v-card-title>

                                <v-card-text>
                                    <v-text-field type="number" step="0.1" outlined dense v-model="model.mixte" :rules="rules"></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <div>
                        <v-btn
                            depressed
                            color="primary"
                            class="ml-4"
                            type="submit"
                        >
                            Enregistrer
                        </v-btn>

                        <v-btn
                            depressed
                            color="default"
                            class="ml-4"
                            v-if="!is_saved"
                            @click="cancel()"
                        >
                            Annuler
                        </v-btn>
                    </div>
                </v-form>

                
            </v-card-text>
        </v-card>

        <application-percentage 
            :percent="saved"
            :save="is_saved"
        >
        </application-percentage>
    </v-app>
</template>

<script>
    import Axios from 'axios';
    import ApplicationPercentage from './application.vue';

    export default {
        name: 'percentages',
        props: ['percentage'],
        components: { ApplicationPercentage },
        data: () => ({
            model: {
                chez: null,
                deux: null,
                trois: null,
                quatre: null,
                cinq: null,
                mixte: null,
            },

            saved: {
                chez: null,
                deux: null,
                trois: null,
                quatre: null,
                cinq: null,
                mixte: null,
            },

            rules: [
                value => !!value || 'Réquise.',
                value => (!isNaN(parseFloat(value, 10))) || 'La valeur doit être un nombre',
            ],

            baseUrl: window.baseUrl + 'admin/',
            loading: false
        }),

        watch: {
            percentage: {
                handler(val){
                    this.model = {
                        chez: val.chez_les_habitants * 100,
                        deux: val.deux_etoiles * 100,
                        trois: val.trois_etoiles * 100,
                        quatre: val.quatre_etoiles * 100,
                        cinq: val.cinq_etoiles * 100,
                        mixte: val.mixtes * 100
                    }

                    this.saved = {
                        chez: val.chez_les_habitants * 100,
                        deux: val.deux_etoiles * 100,
                        trois: val.trois_etoiles * 100,
                        quatre: val.quatre_etoiles * 100,
                        cinq: val.cinq_etoiles * 100,
                        mixte: val.mixtes * 100
                    }
                },
                immediate: true
            }
        },

        computed: {
            is_saved(){
                if(!_.isEqual(this.convertFloatObj(this.model), this.convertFloatObj(this.saved))){
                    return false
                }

                return true
            }
        },

        methods: {
            save(){
                this.loading = true

                let datas = {
                    chez_les_habitants: this.model.chez / 100,
                    deux_etoiles: this.model.deux / 100,
                    trois_etoiles: this.model.trois / 100,
                    quatre_etoiles: this.model.quatre / 100,
                    cinq_etoiles: this.model.cinq / 100,
                    mixtes: this.model.mixte / 100
                }

                Axios.put(this.baseUrl + 'percentages/'+this.percentage.id, datas).then(response => {
                    if(response.data.success){
                        this.saved = {
                            chez: response.data.data.chez_les_habitants * 100,
                            deux: response.data.data.deux_etoiles * 100,
                            trois: response.data.data.trois_etoiles * 100,
                            quatre: response.data.data.quatre_etoiles * 100,
                            cinq: response.data.data.cinq_etoiles * 100,
                            mixte: response.data.data.mixtes * 100,
                        }

                        this.model = {
                            chez: response.data.data.chez_les_habitants * 100,
                            deux: response.data.data.deux_etoiles * 100,
                            trois: response.data.data.trois_etoiles * 100,
                            quatre: response.data.data.quatre_etoiles * 100,
                            cinq: response.data.data.cinq_etoiles * 100,
                            mixte: response.data.data.mixtes * 100,
                        }
                        this.$toaster.success(response.data.success);
                    }

                    if(response.data.error){
                        this.$toaster.error("Une erreur est survenue, veuillez réessayer");
                        console.log('error', response.data.error)
                    }
                }).catch(error => {
                    this.$toaster.error("Une erreur est survenue, veuillez réessayer");
                    console.log('error', error)
                }).finally(() => {
                    this.loading = false
                })
            },

            cancel(){
                this.model = this.saved
            },

            convertFloatObj(obj) {
                var res = Object.assign(Object.create(Object.getPrototypeOf(obj)), obj)
                for (const [key, value] of Object.entries(obj)){
                    res[key] = parseFloat(value)
                }
                return res;
            }
        }
    }
</script>