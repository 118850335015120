<template>
    <v-app>
        <v-overlay
            :value="loading"
        >
            <v-progress-circular
                indeterminate
                color="white"
                :size="50"
            ></v-progress-circular>
        </v-overlay>
        <div>
            <form
                enctype="multipart/form-data"
                @submit.prevent="updateArticle()"
            >
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="article-title"> Titre de l'article: </label>
                            <v-text-field
                                outlined
                                id="article-title"
                                v-model="blog.title"
                                :success="blog.title ? true : false"
                                :error="!blog.title ? true : false"
                            ></v-text-field>
                        </div>
                    </div>
                    
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="destination">Destination associée:</label>
                            <v-select
                                id="destination"
                                :items="destinations"
                                v-model="blog.destination_id"
                                item-value="id"
                                item-text="title_destination"
                                outlined
                                :success="blog.destination_id ? true : false"
                                :error="!blog.destination_id ? true : false"
                            >
                            </v-select>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="categorie"> Catégorie de l'article: </label>
                            <v-select
                                id="categorie"
                                :items="categories"
                                v-model="blog.id_categorie"
                                item-value="id"
                                item-text="titre"
                                outlined
                                :success="blog.id_categorie ? true : false"
                                :error="!blog.id_categorie ? true : false"
                            >
                            </v-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="article-slug"> Slug: </label>
                            <v-text-field
                                outlined
                                id="article-slug"
                                v-model="blog.slug"
                                :success="blog.slug ? true : false"
                                :error="!blog.slug ? true : false"
                            ></v-text-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="article-description">Description de l'article:</label>
                            <v-textarea
                                outlined
                                id="article-description"
                                v-model="blog.description"
                                :success="blog.description ? true : false"
                                :error="!blog.description ? true : false"
                            ></v-textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="article">Détails de votre article:</label>
                            <div class="editor-wisiwyg">
                                <quill-editor
                                    ref="myQuillEditor"
                                    @hook:mounted="setQuill"
                                    v-model="blog.detail"
                                    :options="{
                                        modules: {
                                            imageResize: {},
                                            videoResize: {},
                                            toolbar: {
                                                container: [
                                                ['bold', 'italic', 'underline', 'strike'],
                                                ['blockquote', 'code-block'],
                                                [{ header: 1 }, { header: 2 }],
                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                                [{ script: 'sub' }, { script: 'super' }],
                                                [{ indent: '-1' }, { indent: '+1' }],
                                                [{ direction: 'rtl' }],
                                                [{ size: ['small', false, 'large', 'huge'] }],
                                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                [{ color: [] }, { background: [] }],
                                                [{ font: [] }],
                                                [{ align: [] }],
                                                ['clean'],
                                                ['link', 'image', 'video'],
                                                ],
                                                handlers: {
                                                    image: image_handler,
                                                },
                                            },
                                        },
                                    }"
                                /> 
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4 input-button">
                        <div ref="upload-button" class="upload-button form-control" @click="uploadFile">
                            <i class="mdi mdi-upload" aria-hidden="true"></i>
                        </div>
                        <div>
                            <input
                                style="border: 1px solid gree; display: none"
                                type="file"
                                accept="image/*"
                                multiple
                                placeholder="Vos image ou video"
                                class="form-control image_video dropify"
                                @change="piece_jointe($event)"
                                ref="piece"
                            />
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="file-zone form-control">
                            <div  v-if="!blog.fichier" class="input-zone" ref="input-zone">
                                <i class="mdi mdi-file" aria-hidden="true"></i>
                                <p>Aperçu du fichier</p>
                            </div>

                            <div v-else class="input-zone">
                                <img :src="blog.fichier" class="piece-show"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div>
                                <label for="seo-title"> Titre SEO: </label>
                                <v-text-field
                                    outlined
                                    id="seo-title"
                                    v-model="blog.seo_title"
                                ></v-text-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <div>
                                <label for="seo-meta"> Meta SEO: </label>
                                <v-textarea
                                    outlined
                                    id="article-description"
                                    v-model="blog.seo_meta"
                                ></v-textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="publish" class="">
                                Publier maintenant :
                            </label>
                            <input
                                type="checkbox"
                                v-model="blog.now"
                                :value="blog.now"
                                name="publish"
                                id="publish"
                            />
                            
                        </div>
                    </div>
                    <div class="col-md-6" v-if="blog.now == 0">
                        <div class="form-check">
                            <label for="date" class=""
                            >Date de publication :
                            </label>
                            
                        </div>
                        <div>
                            <v-date-picker v-model="blog.publish" :allowed-dates="allowed_date"></v-date-picker>
                        </div>
                        <div v-if="blog.publish">
                            <button class="btn btn-primary" @click="blog.publish = null">
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                Vidéo associée
                            </div>
                            <div class="card-body">
                                <div class="row" v-if="blog.videos.length > 0">
                                    <div class="col-md-4" v-for="video in blog.videos" :key="video.id">
                                        <div class="card">
                                            <div class="card-header">
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        {{ video.titre }}
                                                    </div>
                                                    <v-tooltip top>
                                                        <template  v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                text
                                                                icon
                                                                color="red"
                                                                @click="confirm_dissociate_video(video)"
                                                            >
                                                                <v-icon>mdi-delete</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Dissocier la vidéo</span>
                                                    </v-tooltip>
                                                </div>
                                                
                                            </div>
                                            <div class="card-bod">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <iframe 
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            style="width: 100%;height: 270px"
                                                            :src="video.url" 
                                                            :title="video.titre" frameborder="0" 
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                                                        >
                                                        </iframe>
                                                    </template>
                                                    <span>{{ video.description }}</span>
                                                </v-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-center align-items-center" v-else>
                                    Aucune vidéo associée
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="col-md-4 col-xs-4 col-sm-4">
                            <button type="submit" class="btn-save btn btn-info">Mettre à jour</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <v-dialog
            v-model="dialog"
            width="500"
        >
            <div class="card" v-if="to_dissociate">
                <div class="card-body">
                    Voulez-vous vraiment dissocier la video {{ to_dissociate.titre }} de l'article ?
                </div>

                <div class="card-footer">
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="red"
                        text
                        @click="dissociate_video()"
                    >
                        Confirmer
                    </v-btn>
                </div>
            </div>
        </v-dialog>
    </v-app>
</template>

<script>
    import Vue from "vue";
    import VueQuillEditor, { Quill } from 'vue-quill-editor'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import ImageResize from 'quill-image-resize-vue';
    import VideoResize from 'quill-video-resize-module';

    Vue.use(VueQuillEditor)

    Quill.register('modules/imageResize', ImageResize);
    Quill.register('modules/videoResize', VideoResize);

    import Axios from "axios";

    export default {
        name: 'update-blog',
        props: {
            article: Object,
            destinations: Array,
            categories: Array
        },
        data(){
            return {
                blog: {
                    id_article: this.article.id,
                    destination_id: this.article.destination_id,
                    id_categorie: this.article.id_categorie,
                    title: this.article.title,
                    slug: this.article.slug,
                    description: this.article.description,
                    fichier: null,
                    seo_title: this.article.seo ? this.article.seo.titre : null,
                    seo_meta: this.article.seo ? this.article.seo.meta : null,
                    videos: this.article.video_article,
                    detail: this.article.detail,
                    file: null,
                    now: this.article.published_at ? 1 : 0,
                    publish: this.article.published_to,
                    published: this.article.published_at
                },
   

                path: window.baseUrl + "storage/",
                baseUrl: window.baseUrl + 'admin/',

                loading: false,
                dialog: false,
                to_dissociate: null
            }
        },

        watch:{
            'blog.now': {
                handler(value){
                    if(value == 1){
                        this.blog.publish = null
                    }
                }
            }
        },

        methods: {
            allowed_date(val){
                return val > new Date().toISOString()
            },
            setQuill() {
                this.quill = this.$refs.myQuillEditor.quill;
            },

            set_image(data) {
                Axios.post(this.baseUrl +"imgeditor", data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "application/json",
                    },
                }).then((response) => {
                    let range = this.quill.getSelection(true);
                    this.quill.insertEmbed(range.index, "image", this.path + response.data.data);
                    this.quill.setSelection(range.index + 1);
                });
            },

            image_handler(image) {
                const input = document.createElement("input");
                input.setAttribute("type", "file");
                input.click();

                // Listen upload local image and save to server
                input.onchange = () => {
                    var file = input.files[0];

                    // file type is only image.
                    if (/^image\//.test(file.type)) {
                        var formData = new FormData();
                        formData.append("image", file);
                        this.set_image(formData);
                    } else {
                        console.warn("You could only upload images.");
                    }
                };
            },

            updateArticle(){
                this.loading = true;
                var form_data = new FormData()

                form_data.append('id_categorie', this.blog.id_categorie)
                form_data.append('title', this.blog.title)
                form_data.append('destination_id', this.blog.destination_id)
                form_data.append('description', this.blog.description)
                form_data.append('piecejointe', this.blog.file)
                form_data.append('detail', this.blog.detail)
                form_data.append('seo_title', this.blog.seo_title)
                form_data.append('seo_meta', this.blog.seo_meta)
                form_data.append('slug', this.blog.slug)
                form_data.append('now', this.blog.now)
                form_data.append('published_to', this.blog.publish)

                Axios.post(this.baseUrl + "updateblog/" + this.blog.id_article, form_data).then((response) => {
                    if(response.data.success){
                        this.$toaster.success(response.data.success);
                        this.loading = false;
                    }else{
                        this.$toaster.error("Une erreur est survenue, veuillez réessayer.");
                        this.loading = false;
                    }
                })
            },

            uploadFile(){
                this.$refs.piece.click()
            },

            piece_jointe: function (event) {
                var input = event.target;
                
                if (input.files && input.files[0]) {
                    this.blog.file = input.files[0]
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.blog.fichier = e.target.result
                    };

                    reader.readAsDataURL(input.files[0]);
                }
            },

            confirm_dissociate_video(item){
                this.to_dissociate = item;
                this.dialog = true;
            },

            dissociate_video(){
                this.loading = true;
                Axios.post(this.baseUrl + 'dissociate-video', {
                    id_article: this.blog.id_article,
                    id_video: this.to_dissociate.id
                }).then((response) => {
                    if(response.data.success){
                        let index = this.blog.videos.findIndex(item => item.id == this.to_dissociate.id);
                        this.blog.videos.splice(index, 1)
                        this.dialog = false;
                        this.to_dissociate = null;
                        this.$toaster.success(response.data.success);
                        this.loading = false;
                    }else{
                        this.dialog = false;
                        this.to_dissociate = null;
                        this.$toaster.error("Une erreur est survenue, veuillez réessayer.");
                        this.loading = false;
                    }
                })
            }
        },

        mounted(){
            this.blog.fichier = this.path + 'back/img/blogs/' + this.article.piecejointe
        }
    }
</script>