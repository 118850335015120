<template>
    <v-app>
        <v-card flat>
            <v-card-title>
                Gestion de Slug
            </v-card-title>

            <v-card-text>
                <v-tabs
                    v-model="tab"
                    fixed-tabs
                    background-color="#00A2A1"
                    dark
                >
                    <v-tabs-slider color="#F7941D"></v-tabs-slider>
                    <v-tab>
                        Circuits
                    </v-tab>

                    <v-tab>
                        Déstinations
                    </v-tab>

                    <v-tab>
                        Articles
                    </v-tab>

                    <v-tab>
                        Catégories circuit
                    </v-tab>

                    <v-tab>
                        Catégories article
                    </v-tab>

                    <v-tab>
                        Continents
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <circuits-slug @termined="termined = false" @edit="edit" :baseurl="baseUrl" :selected="tab == 0 ? true : false" :is_loading="termined"></circuits-slug>
                    </v-tab-item>

                    <v-tab-item>
                        <destinations-slug @termined="termined = false" @edit="edit" :baseurl="baseUrl" :selected="tab == 1 ? true : false" :is_loading="termined"></destinations-slug>
                    </v-tab-item>

                    <v-tab-item>
                        <articles-slug @termined="termined = false" @edit="edit" :baseurl="baseUrl" :selected="tab == 2 ? true : false" :is_loading="termined"></articles-slug>
                    </v-tab-item>

                    <v-tab-item>
                        <thematiques-slug @termined="termined = false" @edit="edit" :baseurl="baseUrl" :selected="tab == 3 ? true : false" :is_loading="termined"></thematiques-slug>
                    </v-tab-item>

                    <v-tab-item>
                        <categories-article-slug @termined="termined = false" @edit="edit" :baseurl="baseUrl" :selected="tab == 4 ? true : false" :is_loading="termined"></categories-article-slug>
                    </v-tab-item>

                    <v-tab-item>
                        <continents-slug @termined="termined = false" @edit="edit" :baseurl="baseUrl" :selected="tab == 5 ? true : false" :is_loading="termined"></continents-slug>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>

        <v-dialog
            v-model="modal"
            max-width="680"
            persistent
        >
            <v-overlay
                :value="loading"
            >
                <v-progress-circular
                    indeterminate
                    color="white"
                    :size="50"
                ></v-progress-circular>
            </v-overlay>

            <v-card v-if="item">
                <v-card-title>
                    Modification slug pour {{ ' ' + item.header + ': ' + item.titre}}
                </v-card-title>

                <v-form @submit.prevent="save()">
                    <v-card-text>
                        <v-text-field outlined dense label="Entrer le slug" v-model="item.slug"></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            class="mr-2"
                            color="primary"
                            @click="close()"
                        >
                            Annuler
                        </v-btn>

                        <v-btn
                            class="mr-2"
                            color="success"
                            type="submit"
                        >
                            Enregistrer
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import CircuitsSlug from './circuit.vue';
    import DestinationsSlug from './destination.vue';
    import ContinentsSlug from './continent.vue';
    import ArticlesSlug from './article.vue';
    import ThematiquesSlug from './thematique.vue';
    import CategoriesArticleSlug from './categorie-article.vue';
    import Axios from 'axios';

    export default {
        name: 'slug',
        components: {
            CircuitsSlug,
            DestinationsSlug,
            ContinentsSlug,
            ArticlesSlug,
            ThematiquesSlug,
            CategoriesArticleSlug,
        },

        data: () => ({
            baseUrl: window.baseUrl + 'admin/',
            tab: null,
            loading: false,
            termined: false,
            modal: false,
            item: {
                id: null,
                slug: null,
                model: null,
                titre: null,
                header: null
            }
        }),

        methods: {
            edit(item){
                if(item.model == 'circuit'){
                    this.item = {
                        id: item.item.id,
                        slug: item.item.url ? item.item.url.value : null,
                        model: item.item.version ? "App\\CircuitCopy" : "App\\Circuits",
                        titre: item.item.title_circuits,
                        header: 'le circuit'
                    }
                }

                if(item.model == 'destination'){
                    this.item = {
                        id: item.item.id,
                        slug: item.item.url ? item.item.url.value : null,
                        model: "App\\Destinations",
                        titre: item.item.title_destination,
                        header: 'la destination'
                    }
                }

                if(item.model == 'article'){
                    this.item = {
                        id: item.item.id,
                        slug: item.item.slug,
                        model: "App\\Article",
                        titre: item.item.title,
                        header: "l'article"
                    }
                }

                if(item.model == 'continent'){
                    this.item = {
                        id: item.item.id,
                        slug: item.item.url ? item.item.url.value : null,
                        model: "App\\Continent",
                        titre: item.item.continent_name,
                        header: 'le continent'
                    }
                }

                if(item.model == 'thematique'){
                    this.item = {
                        id: item.item.id,
                        slug: item.item.url ? item.item.url.value : null,
                        model: "App\\Thematiques",
                        titre: item.item.titre_thematique,
                        header: 'la thématique'
                    }
                }

                if(item.model == 'categorie-article'){
                    this.item = {
                        id: item.item.id,
                        slug: item.item.url ? item.item.url.value : null,
                        model: "App\\categorie_article",
                        titre: item.item.titre,
                        header: "la catégorie d'article"
                    }
                }

                this.modal = true;
            },

            save(){
                this.loading = true;
                let data = {
                    id: this.item.id,
                    slug: this.item.slug,
                    type: this.item.model
                }
                Axios.post(this.baseUrl + 'slug', data).then(response => {
                    if(response.data.success){
                        this.$toaster.success(response.data.success);
                    }

                    if(response.data.error){
                        this.$toaster.error("Une erreur est survenue, veuillez réessayer");
                        console.log('error banniere', response.data.error)
                    }
                }).finally(() => {
                    this.loading = false;
                    this.termined = true;
                    this.close()
                })
            },

            close(){
                this.modal = false;
                this.item = {
                    id: null,
                    slug: null,
                    model: null,
                    titre: null,
                    header: null
                };
            }
        }
    }
</script>