<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <form
                    action=""
                    enctype="multipart/form-data"
                    @submit.prevent="sendSlides()"
                >
                    <div>
                        <h3>Création de slide et galerie</h3>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    
                                    <div class="card">
                                        <div class="card-header">
                                            <label for="type">Type</label>
                                        </div>
                                        <div class="card-body d-flex">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <input
                                                    type="radio"
                                                    v-model="types"
                                                    value="Photo"
                                                    id="photo"
                                                    name="photo"
                                                />
                                                <label class="mb-0 ml-2" for="photo">Photo</label>
                                            </div>
                                            <div class="d-flex justify-content-center align-items-center ml-4">
                                                <input
                                                    type="radio"
                                                    v-model="types"
                                                    value="Video"
                                                    id="video"
                                                />
                                                <label class="mb-0 ml-2" for="video">Vidéo</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <div class="card">
                                        <div class="card-header">
                                            <label for="title">Titre du slide:</label>
                                        </div>
                                        <div class="card-body">
                                            <input
                                                
                                                type="text"
                                                placeholder="titre"
                                                v-model="title_slide"
                                                class="w-100 border-bottom"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="types == ''">
                            <h4>Cochez l'un de ces types</h4>
                        </div>

                        <div v-else-if="types == 'Photo'">
                            <div class="card mb-2" v-for="(slide, s) in slideGalerie" :key="s">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="page">Titre de la galerie:</label><br />
                                                <input
                                                    type="text"
                                                    placeholder="slide galerie"
                                                    v-model="slide.page"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Position:</label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    v-model="slide.position"
                                                    rows="6"
                                                    
                                                    min="0"
                                                    :max="slideGalerie.length - 1"
                                                    ref="position"
                                                    @keyup="ruleInput(s)"
                                                    disabled
                                                    style="background-color: white !important"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="title">Lien du slide:</label><br />
                                                <input
                                                    type="url"
                                                    placeholder="https://example.com"
                                                    pattern="https://.*"
                                                    size="30"
                                                    v-model="slide.lien"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="title">Titre du bouton:</label><br />
                                                <input
                                                    type="text"
                                                    placeholder="Découvrir"
                                                    v-model="slide.titre_button"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Description</label>
                                                <textarea
                                                    class="form-control"
                                                    v-model="slide.description"
                                                    rows="8" style="height: 217px;
}"
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label>Photo</label>
                                                <span class="ml-2" style="display: none">
                                                    <i class="fa fa-paperclip">
                                                    <input
                                                        
                                                        type="file"
                                                        accept="image/*"
                                                        class="form-control piece_jointe"
                                                        @change="slide_gallerys($event, s)"
                                                        ref="gallery"
                                                    /></i>
                                                    Joindre un fichier
                                                </span>

                                                <div class="img-gallery w-100 border border-secondary rounded" @click="getFiles(s)">
                                                    <div class="embed-file" v-if="slide.cover_slide">
                                                        <img :src="slide.cover_slide" alt="">
                                                    </div>

                                                    <div class="embed-input d-flex justify-content-center align-items-center">
                                                        <i v-if="!slide.cover_slide" class="mdi mdi-file" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-footer d-flex justify-content-between" v-if="slideGalerie.length > 1">
                                    <button
                                        @click="deleteSlideGalerie(s)"
                                        type="button"
                                        class="btn btn-danger btn-sm icon-btn ml-2"
                                    >
                                        <i class="mdi mdi-delete"></i>
                                    </button>

                                    <div class="postion-control">
                                        <i 
                                            v-if="s > 0" 
                                            class="mdi mdi-arrow-up-bold-box" 
                                            data-toggle="tooltip" 
                                            data-placement="top" 
                                            title="Monter d'une position"
                                            @click="upPosition(s)"
                                        ></i>

                                        <i 
                                            v-if="s < slideGalerie.length - 1" 
                                            class="mdi mdi-arrow-down-bold-box" 
                                            data-toggle="tooltip" 
                                            data-placement="top" 
                                            title="Descendre d'une position"
                                            @click="downPosition(s)"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-4 col-sm-4" style="margin-left:-19px">
                                <button
                                    @click="addSlideGalerie()"
                                    type="button"
                                    class="btn btn-info btn-sm icon-btn ml-2"
                                >
                                    <i class="mdi mdi-plus"></i>
                                </button>
                            </div>
                        </div>

                        <div v-else-if="types == 'Video'">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-header">
                                            <label class="mb-0" for="titre">Titre de la Vidéo</label>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <input type="text" id="titre" v-model="videos.titre"  class="w-100 border-bottom" placeholder="Titre de la vidéo"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-8">
                                    <div class="card">
                                        <div class="card-header">
                                            <label class="mb-0" for="slide-video">Vidéo</label>
                                            <i 
                                                class="mdi mdi-file ml-2 upload-video" 
                                                aria-hidden="true" 
                                                @click="getFileVideo"
                                                data-toggle="tooltip" 
                                                data-placement="top" 
                                                title="Upload vidéo"
                                            >
                                                Joindre une vidéo
                                            </i>
                                            <input
                                                type="file"
                                                accept="video/mp4,video/x-m4v,video/*"
                                                class="form-control piece_jointe"
                                                
                                                @change="video($event)"
                                                style="display: none"
                                                id="slide-video"
                                                ref="filevideo"
                                            />
                                            <span class="text-danger ml-2" v-if="errors">{{ errors }}</span>
                                        </div>

                                        <div class="card-body">
                                            <div v-if="videos.video">
                                                <video
                                                    style="width: 100%; height: auto"
                                                    controls
                                                    :src="videos.video"
                                                ></video>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="form-group">
                                        <div v-if="videos.video">
                                            <video
                                                style="width: 500px; height: 300px"
                                                autoplay
                                                controls
                                                :src="videos.video"
                                            ></video>
                                        </div>
                                        
                                        <label>Video</label>
                                        <span>
                                            <i class="fa fa-paperclip">
                                                <input
                                                    type="file"
                                                    accept="video/mp4,video/x-m4v,video/*"
                                                    class="form-control piece_jointe"
                                                    @change="video($event)"
                                                />
                                            </i>
                                            Joindre fichier
                                        </span>
                                        <span class="text-danger" v-if="errors">{{ errors }}</span>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top:-5px">
                        <div class="col-md-4 col-xs-4 col-sm-4">
                            <button class="btn-save btn btn-primary">Enregistrer</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Axios from "axios";
import VueRepeater from "vue-repeater";
import "vue-repeater/dist/lib/vue-repeater.css";

Vue.config.productionTip = false;
Vue.component("vue-repeater", VueRepeater);

export default {
    data() {
        return {
            base_url: window.baseUrl + 'admin/',
            slideGalerie: [
                {
                    description: "",
                    position: "0",
                    page: "",
                    cover_slide: "",
                    lien: "",
                    titre_button: "Découvrir",
                },
            ],
            videos: {
                titre: "",
                video: "",
            },

            titre_video: "",
            types: "Photo",
            //page: "",
            title_slide: "",
            description: "",
            gallerys: [],
            listSlides: [],
            slidevideo: [],
            slidelist: [],
            title_type: "",
            ListTypeSlide: [],
            listDestinations: [],
            id_slide: [],
            id: [],
            ListFusion: [],
            checked: true,
            checked_slide: false,
            slides: [],
            errors: null,
        };
    },

    watch: {
        
    },

    methods: {
        addSlideGalerie() {
            this.slideGalerie.push({
                description: "",
                position: this.slideGalerie.length,
                page: "",
                cover_slide: "",
                lien: "",
                titre_button: "Découvrir",
            });
        },
        deleteSlideGalerie(index) {
            this.slideGalerie.splice(index, 1);
            this.slideGalerie.map((item, index) => {
                item.position = index.toString()
            })
        },

        upPosition(s){
            this.slideGalerie[s - 1] = this.slideGalerie.splice(s, 1, this.slideGalerie[s - 1])[0];
            this.slideGalerie.map((item, index) => {
                item.position = index.toString()
            })
        },

        downPosition(s){
            this.slideGalerie[s] = this.slideGalerie.splice(s - 1, 1, this.slideGalerie[s])[0];
            this.slideGalerie.map((item, index) => {
                item.position = index.toString()
            })
        },

        slide_gallerys: function (event, index) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                this.slideGalerie[index].cover_slide = e.target.result
                };
                reader.readAsDataURL(input.files[0]);
            }
        },

        sendSlides() {
            if (this.types == "") {
                this.$toaster.error("Veuillez cocher l'un de ces types");
            }

            if (this.types == "Photo") {
                Axios.post(this.base_url + "sendSlides", {
                types: this.types,
                title_slide: this.title_slide,
                slideGalerie: this.slideGalerie,
                }).then((response) => {
                    this.$toaster.success("Slide ajouté avec succès");
                  window.location.reload()
                });
            }

            if (this.types == "Video") {
                const part = this.videos.video;
                Axios.post(this.base_url + "sendSlides", {
                types: this.types,
                title_slide: this.title_slide,
                titre_video: this.videos.titre,
                slidevideo: part,
                }).then((response) => {
                    this.$toaster.success("Slide ajouté avec succès");
                   window.location.reload()
                });
            }

        },
        video: function (event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                if((input.files[0].size / (1024*1024)).toFixed(2) > 10){
                    this.errors = "La taille max est de 10mo";
                    input.value = "";
                }else{
                    this.errors = null;
                    var reader = new FileReader();
                        reader.onload = (e) => {
                        this.videos.video = e.target.result;
                    };
                    reader.readAsDataURL(input.files[0]);
                }
                
            }
        },

        sendTypeSlide() {
            // alert('tong\'ato e!')
            Axios.post(this.base_url + "sendTypeSlide", {
                title_type: this.title_type,
                id_slide: this.slides,
            }).then((response) => {
                this.ListTypeSlide = response.succ ? response.succ : this.ListTypeSlide;
            });
        },

        affectation() {
            Axios.post(this.base_url + "affectation", {
                id: this.id,
                id_slide: this.id_slide,
            }).then((response) => {
                this.ListFusion = response.succ ? response.succ : this.ListFusion;
            });
        },

        getAllSlides() {
            Axios.get(this.base_url + "getAllSlides").then((response) => {
                this.slidelist = response.data.slides;
            });
        },

        getFiles(s){
            this.$refs.gallery[s].click()
            
        },

        getFileVideo(){
            this.$refs.filevideo.click()
        },

        ruleInput(s){
            this.$refs.position[s].value = (parseInt(this.$refs.position[s].value) > this.slideGalerie.length -1) 
                                                    ? this.slideGalerie.length -1 
                                                    : (parseInt(this.$refs.position[s].value) < 0 ? 0 : this.$refs.position[s].value);
        }
    },

    mounted() {
        this.getAllSlides();
    },
};
</script>

<style>
    .img-gallery{
        height: 300px;
        cursor: pointer;
        position: relative;
    }

    .img-gallery i{
        font-size: 30px;
    }

    .embed-file, .embed-input{
        width: 100%;
        height: 100%;
    }

    .embed-input{
        position: absolute;
        z-index: 2;
        transition: 0.5s;
    }

    .embed-input:hover{
        background-color: #4c4c4cd1;
        transition: 0.5s;
    }

    .embed-file{
        position: absolute;
        z-index: 1;
    }

    .embed-file img{
        height: 100%;
        width: 100%;
    }

    .postion-control i{
        font-size: 35px;
        cursor: pointer;
    }

    .upload-video{
        font-size: 16px;
        cursor: pointer;
    }
</style>
