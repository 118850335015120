<template>
    <div>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="6">
                        <div class="d-flex">
                            <v-checkbox
                                v-model="onlyinused"
                                value="check"
                                :disabled="loading"
                            >
                                <template v-slot:label>
                                    Afficher les fichiers inutilisés seulement
                                </template>
                            </v-checkbox>

                            <div class="d-flex align-items-center">
                                <v-btn
                                    :disabled="loading"
                                    v-if="fileselected.length > 0"
                                    class="mx-2"
                                    dark
                                    small
                                    color="error"
                                    title="Supprimer tous les fichiers séléctionnés"
                                    @click="deleteItem()"
                                >
                                    Supprimer tous les fichiers séléctionnés
                                    <v-icon dark>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </div>
                        </div>
                        
                    </v-col>

                    <v-col cols="6">
                        <div class="d-flex justify-content-end">
                            <v-btn
                                :disabled="loading"
                                class="mx-2"
                                fab
                                dark
                                small
                                color="primary"
                                @click="retrieve()"
                            >
                                <v-icon dark>
                                    mdi-refresh
                                </v-icon>
                            </v-btn>

                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                :color="mode ? 'purple' : 'teal'"
                                :title="mode ? 'Liste' : 'Grille'"
                                @click="mode = !mode"
                            >
                                <v-icon v-if="!mode" dark>
                                    mdi-apps
                                </v-icon>

                                <v-icon v-if="mode" dark>
                                    mdi-view-list
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-title></v-card-title>

            <v-card-text>
                <storage-list-index
                    v-if="mode"
                    :isInused="isInused"
                    :deleteItem="deleteItem"
                    :getStatus="getStatus"
                    :checkItem="checkItem"
                    :isChecked="isChecked"
                    :selectinused="selectinused"
                    :visualize="visualize"
                    :loading="loading"
                    :items="items"
                    @checkall="checkAll"
                ></storage-list-index>

                <storage-grill-index
                    v-if="!mode"
                    :isInused="isInused"
                    :deleteItem="deleteItem"
                    :getStatus="getStatus"
                    :checkItem="checkItem"
                    :isChecked="isChecked"
                    :selectinused="selectinused"
                    :visualize="visualize"
                    :loading="loading"
                    :items="items"
                    @checkall="checkAll"
                ></storage-grill-index>
            </v-card-text>
        </v-card>

        <storage-visualize-file
            @close="stopVisualize"
            :files="show"
        ></storage-visualize-file>
    </div>
</template>

<style >
    .v-label{
        margin-bottom: 0;
    }
</style>

<script>
    import Axios from 'axios';

    export default {
        name: 'storage-file',

        props: {
            headers: Object,
            selected: Boolean,
            is_loading: Boolean,
            model: String,
            labelName: String,
            type: String,
            headerModal: String
        },

        data(){
            return {
                baseUrl: window.baseUrl + 'api/admin/storage/' + this.model,
                loading: false,
                files: [],
                items: [],
                onlyinused: null,
                selectinused: null,
                fileselected: [],
                fromOther: false,
                show: null,
                mode: true
            }
        },

        watch: {
            is_loading(val){
                if(val){
                    this.retrieve()
                }
            },

            // if tab selected
            selected: {
                handler(val){
                    if(val){
                        this.retrieve()
                    }
                },
                immediate: true
            },

            // if checkbox show only inused file checked
            onlyinused: {
                handler(val){
                    this.setOnlyIsInused(val)
                }
            },

            // if checkbox select only inused file checked
            selectinused: {
                handler(val){
                    this.selectOnlyIsInused(val)
                }
            },

            // if fileselected changed value
            fileselected: {
                handler(val){
                    if(!val){
                        this.fileselected = []
                    }
                },
                immediate: true
            }
        },

        methods: {
            retrieve(){
                this.loading = true;
                try{
                    Axios.get(this.baseUrl, { headers: this.headers }).then(response => {
                        this.files = response.data
                        this.setOnlyIsInused(this.onlyinused)
                    }).catch(e => {
                        this.$toaster.error("Une erreur est survenue, veuillez réessayer \n " + error.message);
                        console.log('error', e)
                    }).finally(() => {
                        this.loading = false;
                        this.$emit('termined');
                    })
                }catch(error){
                    this.$toaster.error("Une erreur est survenue, veuillez réessayer \n " + error.message);
                    console.log('error', e)
                }
            },

            getStatus(val){
                return val ? 'green' : 'red';
            },

            setOnlyIsInused(val){
                if(!val){
                    this.items = this.files
                }else{
                    this.items = [];
                    this.files.forEach(item => {
                        if(!item.target){
                            this.items.push(item)
                        }
                    })
                }

                this.selectOnlyIsInused(this.selectinused)
            },

            selectOnlyIsInused(val){
                if(!this.fromOther){
                    if(!val){
                        this.fileselected = []
                    }else{
                        this.items.forEach(item => {
                            if(!item.target){
                                if(!this.fileselected.includes(item)){
                                    this.fileselected.push(item)
                                }
                            }
                        })
                    }
                }

                this.fromOther = false
            },

            isInused(item){
                return item.target ? false : true;
            },

            deleteItem(item = null){
                this.$emit('isdelete', {
                    model: this.model,
                    type: this.type,
                    storage: item ? [item] : this.fileselected,
                    header: this.headerModal
                })
            },

            isChecked(item){
                return this.fileselected.find(el => 
                    el.filename == item.filename
                    && el.realpath == item.realpath
                    && el.fullurl == item.fullurl
                ) ? true : false
            },

            checkItem(event, item){
                if(event){
                    if(!this.fileselected.includes(item)){
                        this.fileselected.push(item)
                    }
                }else{
                    this.fromOther = true;
                    let i = this.fileselected.findIndex(el => 
                        el.filename == item.filename 
                        && el.path == item.path 
                        && el.realpath == item.realpath 
                        && el.target == item.target 
                    )

                    if(i > -1){
                        this.fileselected.splice(i, 1)
                    }
                }
            },

            visualize(item, other){

                this.show = item
            },

            stopVisualize(){
                this.show = null
            },

            checkAll(event){
                this.selectinused = event
            }
        }
    }
</script>
