<template>
	<div v-if="pager.pages && pager.pages.length" class="pagination">
		<div>
			<ul class="pagination">
				<li class="navigation-item next-item">
					<span class="next-item-content">
						<a class="page-link" @click="setPage(pager.currentPage - 1)">
							<i
								class="fa fa-chevron-left text-grey-noah text-sm"
								aria-hidden="true"
								@click="setPage(pager.currentPage - 1)"
							></i>
						</a>
						<!-- <i
              class="fas fa-angle-double-right ml-3"

            ></i> -->
					</span>
				</li>
			</ul>
		</div>
		<div>
			<ul class="pagination">
				<li class="navigation-item prev-item">
					<span class="prev-item-content">
						<!-- <i class="fas fa-angle-double-left mr-3" ></i> -->
						<a
							class="page-link"
							@click="setPage(pager.currentPage + 1)"
							:style="aStyles"
						>
							<i
								class="fa fa-chevron-right text-grey-noah text-sm"
								aria-hidden="true"
								@click="setPage(pager.currentPage + 1)"
							></i>
						</a>
					</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import paginate from "jw-paginate";

const defaultLabels = {
	first: "Précedent",
	next: "Suivant",
};
const defaultStyles = {
	ul: {
		margin: 0,
		padding: 0,
		display: "inline-block",
	},
	li: {
		listStyle: "none",
		display: "inline",
		textAlign: "center",
	},
	a: {
		cursor: "pointer",
		padding: "6px 12px",
		display: "block",
		float: "left",
	},
};
export default {
	props: {
		items: {
			type: Array,
			required: true,
		},
		initialPage: {
			type: Number,
			default: 1,
		},
		pageSize: {
			type: Number,
			default: 5,
		},
		maxPages: {
			type: Number,
			default: 5,
		},
		labels: {
			type: Object,
			default: () => defaultLabels,
		},
		styles: {
			type: Object,
		},
		disableDefaultStyles: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			pager: {},
			ulStyles: {},
			liStyles: {},
			aStyles: {},
		};
	},
	created() {
		if (!this.$listeners.changePage) {
			throw 'Missing required event listener: "changePage"';
		}
		// set default styles unless disabled
		if (!this.disableDefaultStyles) {
			this.ulStyles = defaultStyles.ul;
			this.liStyles = defaultStyles.li;
			this.aStyles = defaultStyles.a;
		}
		// merge custom styles with default styles
		if (this.styles) {
			this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
			this.liStyles = { ...this.liStyles, ...this.styles.li };
			this.aStyles = { ...this.aStyles, ...this.styles.a };
		}
		// set to initial page
		this.setPage(this.initialPage);
	},
	methods: {
		setPage(page) {
			const { items, pageSize, maxPages } = this;
			// get new pager object for specified page
			const pager = paginate(items.length, page, pageSize, maxPages);
			this.$emit("setPage", pager);
			// get new page of items from items array
			const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);
			// update pager
			this.pager = pager;
			// emit change page event to parent component
			this.$emit("changePage", pageOfItems);
		},
	},
	watch: {
		items() {
			this.setPage(this.initialPage);
		},
	},
};
</script>
<style scoped>
.pagination-container .pagination-content ul .pagination-item-active {
	background-color: #00a2a1 !important;
	color: white;
	border: 1px solid #00a2a1;
	font-weight: normal;
}
a.page-link {
    padding: 8px 10px!important;
}
span.prev-item-content a.page-link {
	padding: 0.5rem 0.75rem !important;
}
</style>
