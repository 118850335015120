<template>
    <v-app>
        <v-card flat>
            <v-card-title class="justify-content-between">
                <div>Liste bannière</div>
                <div>
                    <v-btn fab dark small color="primary" @click="new_banner()">
                        <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Chercher"
                    outlined
                ></v-text-field>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="datas"
                    :items-per-page="5"
                    class="elevation-1"
                    :loading="loading"
                >
                    <template v-slot:item.banniere="{ item }">
                        <v-img
                            :lazy-src="path + item.banniere"
                            max-height="150"
                            max-width="700"
                            :src="path + item.banniere"
                        ></v-img>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <modal-banner
            :update="is_update"
            :modal="modal"
            :banner="update"
            @close="modal = false"
            @refresh-list="getAll()"
            :circuits="circuits"
            :articles="articles"
        ></modal-banner>
    </v-app>
</template>

<script>
import Axios from "axios";
import ModalBanner from "./modal.vue";

export default {
    name: "banniere",
    props: ["circuits", "articles"],

    components: {
        ModalBanner,
    },

    watch: {
        modal(value) {
            if (!value) {
                this.update = null;
            }
        },
    },

    data() {
        return {
            baseUrl: window.origin + "/admin/",
            path: "https://noah-voyages.com/images/banner/",
            loading: false,

            modal: false,
            update: null,

            datas: [],

            search: "",
            headers: [
                {
                    text: "Banniere",
                    align: "start",
                    sortable: false,
                    value: "banniere",
                },
                {
                    text: "Titre",
                    align: "start",
                    sortable: true,
                    value: "title",
                },
                { text: "Actions", value: "actions", sortable: false },
            ],
        };
    },

    computed: {
        is_update() {
            return this.update ? true : false;
        },
    },

    methods: {
        getAll() {
            this.loading = true;
            Axios.get(this.baseUrl + "all-banniere").then((response) => {
                this.datas = response.data.data;
                this.loading = false;
            });
        },

        editItem(item) {
            this.update = item;
            this.modal = true;
        },

        deleteItem(item) {
            if (confirm("Supprimer cette bannière ?")) {
                this.loading = true;
                console.log("item", item);
                Axios.delete(this.baseUrl + "banniere/" + item.id).then(
                    (response) => {
                        this.loading = false;
                        if (response.data.success) {
                            this.$toaster.success(response.data.success);
                            this.getAll();
                        }

                        if (response.data.error) {
                            this.$toaster.error(
                                "Une erreur est survenue, veuillez réessayer"
                            );
                            this.getAll();
                            console.log("error banniere", response.data.error);
                        }
                    }
                );
            }
        },

        new_banner() {
            this.modal = true;
        },
    },

    mounted() {
        this.getAll();
    },
};
</script>
