<template>
  <div class="container">
    <h3 class="text-center">Messages en Archive</h3>
    <div class="messaging">
      <div class="inbox_msg">
        <div class="inbox_people">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="archive-tab"
                data-toggle="tab"
                href="#archive"
                role="tab"
                aria-controls="archive"
                aria-selected="true"
                >Archive
                <span class="badge badge-custome">

                </span></a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="archive"
              role="tabpanel"
              aria-labelledby="archive-tab"
            >
              <div class="inbox_chat">
                <div
                  class="chat_list active_chat"
                  v-for="listDiscussion in listDiscussionA"
                  :key="listDiscussion.id"
                >
                  <div
                    @click="getDiscussionArchive(listDiscussion)"
                    class="chat_people"
                  >
                    <div class="chat_img">
                      <img
                        class="avat"
                        src="https://bootdey.com/img/Content/avatar/avatar1.png"
                        alt=""
                      />
                    </div>
                    <div class="chat_ib">
                      <h4>
                        {{
                          listDiscussion.client != null
                            ? listDiscussion.client.nom +
                              " " +
                              listDiscussion.client.prenom
                            : listDiscussion.customers.nom +
                              " " +
                              listDiscussion.customers.prenom
                        }}
                      </h4>
                      <h5>
                        {{ listDiscussion.discussion[0].message }} <br /><br />
                        &nbsp;&nbsp;<span class="chat_date">
                          {{
                            new Date(listDiscussion.discussion[0].created_at)
                              | dateFormat("DD MMMM YYYY  hh:mm")
                          }}</span
                        >
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="convesationNow"
              role="tabpanel"
              aria-labelledby="convesationNow-tab"
            ></div>
          </div>
        </div>
        <div class="mesgs" v-if="discussionarchive.length != 0">
          <div class="msg_history">
            <div class="card-body height3">
              <div v-for="(discussionarch, dp) in discussionarchive" :key="dp">
                <ul class="chat-list">
                  <li
                    v-bind:class="{
                      in: discussionarch.sender_id != admin_id,
                      out: discussionarch.sender_id == admin_id,
                    }"
                  >
                    <div class="chat-img">
                      <img
                        alt="Avtar"
                        src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      />
                    </div>
                    <div class="chat-body">
                      <div class="chat-message">
                        <h5>{{ discussionarch.message }}</h5>
                        <p
                          v-for="f in discussionarch.discussion_pieces_joint"
                          :key="f.id"
                        >
                          <a
                            :href="baseUrl + imgPathPiece + f.file"
                            target="_black"
                            title="cliquer pour voir"
                          >
                            <i class="fa fa-file"></i> vos fichier
                          </a>
                        </p>
                        <p>
                          {{
                            new Date(discussionarch.created_at)
                              | dateFormat("DD MMMM YYYY  h:mm")
                          }}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Axios from "axios";

export default {
  data() {
    return {
      listDiscussionA: [],
      discussionarchive: [],
      users: null,
      imgPathPiece: "data/images/chat_privee/",
    //   baseUrl: "https://admin.noah-voyages.com/",
    //   baseUrl: "http://localhost/noah-voyage-api/"
      baseUrl: window.baseUrl,
    };
  },

  methods: {
    getDiscussionArchive(listDiscussion) {
      let d = listDiscussion.discussion;
      this.discussionarchive = d.slice().reverse();
      this.chat_discussion_id = listDiscussion.id;
      this.admin_id = listDiscussion.user_id;
    },

    getAllAchive() {
      Axios.get("chat").then((response) => {
        this.listDiscussionA = response.data;
        this.users =
          response.data.listDiscussionA.client != null
            ? response.data.listDiscussionA.client
            : response.data.listDiscussionA.customers;
      });
    },
  },
  mounted() {
    this.getAllAchive();
  },
};
</script>
