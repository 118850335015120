<template>
  <div class="card">
    <div class="card-body">
      <h4>CATEGORIES</h4>
      <div class="tab-content">
        <form @submit.prevent="sendCategorieA()" enctype="multipart/form-data">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
              <div class="form-group input-description">
                <h4>Créer une nouvelle catégorie</h4>
                <form @submit.prevent="sendCategorieA()">
                  <div class="row">
                      <div class="col-md-6">
                          <label for="title_cate"> Titre de la categorie :</label>
                            <input
                                class="form-control"
                                type="text"
                                name="title"
                                id="title_cate"
                                placeholder="nouvelle categorie"
                                v-model="titre"
                            />
                      </div>
                      <div class="col-md-6">
                            <label for="categories_article"
                              >List Categoris articles:</label
                            >
                            <select
                              style="border: 1px solid gray"
                              class="form-control"
                              id="position"
                              v-model="position"
                            >
                              <option
                              aria-placeholder="après"
                                v-for="(list, ls) in listCategorie"
                                :key="ls"
                                :value="list.position"
                                selected:true
                              >
                                {{ list.titre }}
                              </option>
                            </select>
                      </div>
                  </div>
                    <div class="row content_images_pieces_joint">
                      <div
                        class="piece_jointe_container"
                        v-for="(f, index) in icon"
                        :key="index"
                      >
                        <div class="btn-piece">
                          <button
                            v-if="icon.length > 0"
                            @click="supprimer_piece_j(index)"
                            type="button"
                            class="btn del_pieces"
                          >
                            <span><i class="fa fa-trash"></i></span>
                          </button>
                          <br />
                          <i class="material-icons"></i>
                          <embed
                            style="width: 100px; height: 100px"
                            :src="f.file"
                            page="2"
                          />
                        </div>
                        <br />
                      </div>
                    </div>
                  <div class="add_piece_joint">
                    <button
                      title="Ajouter ici votre pieces jointes"
                      type="button"
                      class="btn ajout_pieces"
                    >
                      <span
                        ><i class="fa fa-paperclip"></i> Joindre un
                        fichier</span
                      >
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      class="form-control piece_jointe"
                      @change="piece_jointe($event)"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-4 col-xs-4 col-sm-4">
                  <button class="btn btn-primary" type="submit">
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Axios from "axios";
import Toaster from "v-toaster";
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import "v-toaster/dist/v-toaster.css";

// optional set default imeout, the default is 10000 (10 seconds).
Vue.use(Toaster, { timeout: 1000 });
export default {
  data() {
    return {
      //   baseUrl: "https://admin.noah-voyages.com/",
      //   baseUrl: "http://localhost/noah-voyage-api/",
      baseUrl: window.baseUrl + "admin/",
      listCategorie: [],
      status: [],
      deleteCategorie: "",
      titre: "",
      position:"",
      icon: [],
    };
  },
  methods: {
    piece_jointe: function (event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
            this.icon.push({
              file: e.target.result,
            });
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    supprimer_piece_j(index) {
      this.icon.splice(index, 1);
    },
    sendCategorieA() {
      if (this.titre === "" || this.titre.length < 5 || this.icon.length == 0) {
        if (this.titre === "") {
          this.$toaster.error("Titre obligatoire");
        }

        if (this.position === "") {
          this.$toaster.error("Position obligatoire");
        }

        if (this.titre.length < 5) {
          this.$toaster.error("Titre 5 caractères minimum");
        }

        if (this.icon.length == 0) {
          this.$toaster.error("Icône obligatoire");
        }
      } else {
        Axios.post(this.baseUrl + "sendCategorieA", {
          titre: this.titre,
          icon: this.icon,
          position:this.position,
        }).then((response) => {
          this.cat = response;
          if(response.data.success){
            this.titre = "";
            this.icon = [];
            this.$toaster.success("Catégorie inserée avec succès");
            window.location.reload();
          }else{
            this.$toaster.error("Echec d'envoie");
          }
        });
       
      }
    },
    getAllCategorieArticle() {
      Axios.get(this.baseUrl + "getAllCategorieArticle").then((response) => {
        this.listCategorie = response.data;
      });
    },

    deleteCat() {
      if (this.status == 0) {
        this.$toaster.error("Cochez pour supprimer");
      } else {
        this.$toaster.success("Catégorie supprimée avec succès");
        Axios.post(this.baseUrl + "deletecategorie/", {
          deleteCategorie: this.status,
        }).then((response) => {
          this.deleteCategorie = response.succ
            ? response.succ
            : this.deleteCategorie;
        });
        this.status = [];
      }
    },
  },
  mounted() {
    this.getAllCategorieArticle();
  },
};
</script>
