<template>
  <v-app>
    <div class="card">
      <div class="card-body">
        <h4>Article de Blog</h4>
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="article-tab"
              data-toggle="tab"
              href="#article"
              role="tab"
              aria-controls="home-1"
              aria-selected="true"
              >Nouveau Article</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="video-tab"
              data-toggle="tab"
              href="#video"
              role="tab"
              aria-controls="profile-2"
              aria-selected="false"
            >
              Nouvelle Vidéo
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="association_tab"
              data-toggle="tab"
              href="#association"
              role="tab"
              aria-controls="profile-4"
              aria-selected="false"
              >Association d'un Article à une vidéo</a
            >
          </li>
        </ul>
        <div class="tab-content">
          <div
            class="tab-pane fade show active"
            id="article"
            role="tabpanel"
            aria-labelledby="article-tab"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form
                    @submit.prevent="sendblog()"
                    enctype="multipart/form-data"
                  >
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <div v-if="destin === ''">
                            <label for="destination"
                              >Destination associée:</label
                            >
                            <select
                              style="border: 1px solid red"
                              class="form-control"
                              id="destination"
                              v-model="destin"
                              required="champ obligatoire"
                            >
                              <option
                                v-for="(destination, ld) in listDestinations"
                                :key="ld"
                                :value="destination.id"
                              >
                                {{ destination.title_destination }}
                              </option>
                            </select>
                          </div>
                          <div v-else>
                            <label for="destination"
                              >Destination associée:</label
                            >
                            <select
                              style="border: 1px solid green"
                              class="form-control"
                              id="destination"
                              v-model="destin"
                            >
                              <option
                                v-for="(destination, ld) in listDestinations"
                                :key="ld"
                                :value="destination.id"
                              >
                                {{ destination.title_destination }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group">
                          <div v-if="categories === ''">
                            <label for="title"> Catégorie de l'article: </label>
                            <select
                              style="border: 1px solid red"
                              class="form-control"
                              id="title"
                              v-model="categories"
                              required="champ obligatoire"
                            >
                              <option
                                v-for="(listCatego, lc) in listCategorie"
                                :key="lc"
                                :value="listCatego.id"
                              >
                                {{ listCatego.titre }}
                              </option>
                            </select>
                          </div>
                          <div v-else>
                            <label for="title"> Catégorie de l'article: </label>
                            <select
                              style="border: 1px solid green"
                              class="form-control"
                              id="title"
                              v-model="categories"
                              required="champ obligatoire"
                            >
                              <option
                                v-for="(listCatego, lc) in listCategorie"
                                :key="lc"
                                :value="listCatego.id"
                              >
                                {{ listCatego.titre }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group">
                          <div v-if="article === '' || article.length < 4">
                            <label for="article-title">
                              Titre de l'article:
                            </label>
                            <input
                              style="border: 1px solid red"
                              type="text"
                              id="article-title"
                              v-model="article"
                              placeholder="titre de l'article"
                              required="champ obligatoire"
                              class="form-control"
                            />
                          </div>
                          <div v-else>
                            <label for="article-title">
                              Titre de l'article:
                            </label>
                            <input
                              style="border: 1px solid green"
                              type="text"
                              id="article-title"
                              v-model="article"
                              placeholder="titre de l'article"
                              required="champ obligatoire"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="article-slug"> Slug: </label>
                          <input
                            type="text"
                            id="article-slug"
                            class="form-control"
                            placeholder="slug-de-l-article"
                            required="champ obligatoire"
                            v-model="slug"
                            :style="
                              slug && slug.length > 8
                                ? 'border: 1px solid green'
                                : 'border: 1px solid red'
                            "
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <div
                            v-if="
                              description === '' ||
                              description.length < 15 ||
                              description.length > 250
                            "
                          >
                            <label for="article-description"
                              >Description de l'article:</label
                            >
                            <textarea
                              style="border: 1px solid red"
                              id="article-description"
                              cols="30"
                              rows="10"
                              v-model="description"
                              required="champ obligatoire"
                              placeholder="descripition de l'article"
                              class="form-control"
                            ></textarea>
                          </div>
                          <div v-else>
                            <label for="article-description"
                              >Description de l'article:</label
                            >
                            <textarea
                              style="border: 1px solid green"
                              id="article-description"
                              cols="30"
                              rows="10"
                              v-model="description"
                              required="champ obligatoire"
                              class="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="article">Détails de votre article:</label>
                          <div class="editor-wisiwyg">
                            <quill-editor
                              ref="myQuillEditor"
                              @hook:mounted="setQuill"
                              v-model="data"
                              :options="{
                                modules: {
                                  imageResize: {},
                                  videoResize: {},
                                  toolbar: {
                                    container: [
                                      ['bold', 'italic', 'underline', 'strike'],
                                      ['blockquote', 'code-block'],
                                      [{ header: 1 }, { header: 2 }],
                                      [{ list: 'ordered' }, { list: 'bullet' }],
                                      [{ script: 'sub' }, { script: 'super' }],
                                      [{ indent: '-1' }, { indent: '+1' }],
                                      [{ direction: 'rtl' }],
                                      [
                                        {
                                          size: [
                                            'small',
                                            false,
                                            'large',
                                            'huge',
                                          ],
                                        },
                                      ],
                                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                      [{ color: [] }, { background: [] }],
                                      [{ font: [] }],
                                      [{ align: [] }],
                                      ['clean'],
                                      ['link', 'image', 'video'],
                                    ],
                                    handlers: {
                                      image: image_handler,
                                    },
                                  },
                                },
                              }"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4 input-button">
                        <div
                          ref="upload-button"
                          class="upload-button form-control"
                          @click="uploadFile"
                        >
                          <i class="mdi mdi-upload" aria-hidden="true"></i>
                        </div>
                        <div v-if="fichier === ''">
                          <input
                            style="border: 1px solid red; display: none"
                            type="file"
                            accept="video/*,image/*"
                            multiple
                            placeholder="Vos image ou video"
                            class="form-control image_video"
                            @change="piece_jointe($event)"
                            ref="piece"
                          />
                        </div>
                        <div v-else>
                          <embed style="width: 100px; height: 100px" page="2" />

                          <input
                            style="border: 1px solid gree; display: none"
                            type="file"
                            accept="video/*,image/*"
                            multiple
                            placeholder="Vos image ou video"
                            class="form-control image_video"
                            @change="piece_jointe($event)"
                            ref="piece"
                          />
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class="file-zone form-control">
                          <div
                            v-if="fichier.length === 0"
                            class="input-zone"
                            ref="input-zone"
                          >
                            <i class="mdi mdi-file" aria-hidden="true"></i>
                            <p>Aperçu du fichier</p>
                          </div>

                          <div v-else class="input-zone">
                            <img
                              v-if="tmp_file.type == 'image'"
                              :src="tmp_file.path"
                              class="piece-show"
                            />
                            <video
                              v-if="tmp_file.type == 'video'"
                              controls
                              class="piece-show"
                            >
                              <source :src="tmp_file.path" type="video/mp4" />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <div>
                            <label for="seo-title"> Titre SEO: </label>
                            <input
                              type="text"
                              id="seo-title"
                              placeholder="Titre SEO"
                              class="form-control"
                              v-model="seo.title"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <div>
                            <label for="seo-meta"> Meta SEO: </label>
                            <textarea
                              type="text"
                              id="seo-meta"
                              placeholder="Meta SEO"
                              class="form-control"
                              v-model="seo.meta"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="publish" class=""
                            >Publier maintenant :
                          </label>
                          <input
                            type="checkbox"
                            v-model="now"
                            :value="now"
                            name="publish"
                            id="publish"
                          />

                        </div>
                      </div>
                      <div class="col-md-6" v-if="now == 0">
                        <div class="form-check">
                          <label for="date" class=""
                            >Date de publication :
                          </label>

                        </div>
                        <div>
                            <v-date-picker v-model="publish" :allowed-dates="allowed_date"></v-date-picker>
                        </div>
                        <div v-if="publish">
                            <button class="btn btn-primary" @click="publish = null">
                                Annuler
                            </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-4 col-xs-4 col-sm-4">
                        <button
                          class="btn btn-primary envoyer"
                          type="submit"
                          @click="sendblog()"
                          :disabled="disable_add_article"
                        >
                          Envoyer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ---------------------- section add video ---------------------- -->
          <div
            class="tab-pane fade"
            id="video"
            role="tabpanel"
            aria-labelledby="video-tab"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form
                    action=""
                    @submit.prevent="SendVideo()"
                    enctype="multipart/form-data"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <div
                            v-if="video.titre === '' || video.titre.length < 5"
                          >
                            <label for="titre-video">Titre de la vidéo</label>
                            <input
                              style="border: 1px solid red"
                              type="text"
                              name="titre-video"
                              placeholder="Itineraire"
                              v-model="video.titre"
                              required="champ obligatoire"
                              class="form-control"
                              id="titre-video"
                            />
                          </div>

                          <div v-else>
                            <label for="titre-video">Titre de la vidéo</label>
                            <input
                              class="form-control"
                              style="border: 1px solid green"
                              type="text"
                              name="titre-video"
                              placeholder="Itineraire"
                              v-model="video.titre"
                              required="champ obligatoire"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <div v-if="video.url === ''">
                            <label for="url-video">Vidéo</label>
                            <input
                              class="form-control"
                              style="border: 1px solid red"
                              type="url"
                              name="url"
                              id="url-video"
                              v-model="video.url"
                              placeholder="https://www.youtube.com/watch?v=EaSgftRyvAM"
                              required="champ obligatoire"
                            />
                          </div>
                          <div v-else>
                            <label for="url-video">Vidéo</label>
                            <input
                              class="form-control"
                              style="border: 1px solid green"
                              type="url"
                              name="url"
                              id="url-video"
                              v-model="video.url"
                              placeholder="https://www.youtube.com/watch?v=EaSgftRyvAM"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <div
                            v-if="
                              video.description === '' ||
                              video.description.length < 15
                            "
                          >
                            <label for="descript-video"
                              >Description de la vidéo</label
                            >
                            <textarea
                              class="form-control"
                              style="border: 1px solid red"
                              required="champ obligatoire"
                              name="description"
                              id="descript-video"
                              cols="30"
                              rows="10"
                              v-model="video.description"
                              placeholder=" Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur
                                                            anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait
                                                                que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié"
                            ></textarea>
                          </div>

                          <div v-else>
                            <label for="descript-video"
                              >Description de la vidéo</label
                            >
                            <textarea
                              class="form-control"
                              style="border: 1px solid green"
                              required
                              name="description"
                              id="descript-video"
                              cols="30"
                              rows="10"
                              v-model="video.description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-4 col-xs-4 col-sm-4">
                      <button
                        class="btn btn-primary envoyer"
                        type="submit"
                        @click="SendVideo()"
                      >
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ----------------- section asso video article circuit -------------- -->
          <div
            class="tab-pane fade"
            id="association"
            role="tabpanel"
            aria-labelledby="association-tab"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form
                    action=""
                    id="association"
                    @submit.prevent="association()"
                  >
                    <div class="row">
                      <div class="col-md-12 list_video">
                        <div class="form-group">
                          <div>
                            <label for="title-video-asso"> Titre de la Vidéo: </label>
                            <select
                              :style="
                                V_Aritcle === ''
                                  ? 'border: 1px solid red'
                                  : 'border: 1px solid green'
                              "
                              class="form-control"
                              id="title-video-asso"
                              v-model="V_Aritcle"
                              required="champ obligatoire"
                            >
                              <option
                                v-for="(video, vd) in listVideo"
                                :key="vd"
                                :value="video.id"
                              >
                                {{ video.titre }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 list_article">
                        <div class="form-group">
                          <div>
                            <label for="title-article-asso">
                              Titre de l'Article:
                            </label>
                            <select
                              class="form-control"
                              id="title-article-asso"
                              v-model="article"
                              required="champ obligatoire"
                              :style="
                                article === ''
                                  ? 'border: 1px solid red'
                                  : 'border: 1px solid green'
                              "
                            >
                              <option
                                v-for="(blog, bl) in listBlog"
                                :key="bl"
                                :value="blog.id"
                              >
                                {{ blog.title }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <div>
                            <label for="circuit-asso">Circuit:</label>

                              <v-select
                                  :options="litsCircuitCopy"
                                  label="title_circuits"
                                  dense
                                  outlined
                                  multiple
                                  v-model="circuits"
                              ></v-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-4 col-xs-4 col-sm-4">
                      <button
                        class="btn btn-primary associer"
                        type="submit"
                        @click="association()"
                      >
                        Associer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Vue from "vue";
import VueQuillEditor, { Quill } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import ImageResize from "quill-image-resize-vue";
import VideoResize from "quill-video-resize-module";

Vue.use(VueQuillEditor);

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/videoResize", VideoResize);

import Axios from "axios";
import Toaster from "v-toaster";
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import "v-toaster/dist/v-toaster.css";
export default {
  data() {
    return {
      //   baseUrl: "http://localhost/Noah-voyages/noah-voyage-api/",
      //   baseUrl: "http://localhost:8888/noah-voyages/noah-voyage-api/",
      //   baseUrl: "https://admin.noah-voyages.com/",
      baseUrl: window.baseUrl + "admin/",
      imgPathPiece: "storage/",
      fichier: [],
      title: "",
      slug: "",
      status: [],
      article: "",
      description: "",
      detail: "",
      listDestinations: [],
      destinations: [],
      categories: "",
      circuits: [],
      deleteCategorie: "",
      destin: "",
      action: "",
      // listCountrys: [],
      litsCircuitCopy: [],
      listBlog: [],
      listCategorie: [],
      listVideo: [],
      V_Aritcle: "",
      id: "",
      blog: "",
      VideoArticle: [],
      videoassoc: [],
      ListFusion: [],
      video: {
        url: "",
        titre: "",
        description: "",
      },
      editor: null,
      data: "",
      document: null,
      quill: null,
      //   path: "http://localhost/Noah-voyages/noah-voyage-api/",
      //   path: 'https://admin.noah-voyages.com/storage/',
      path: window.baseUrl + "storage/",
      tmp_file: {
        type: null,
        path: null,
      },
      //   path: "http://localhost:8888/noah-voyages/noah-voyage-api/storage/",
      seo: {
        title: null,
        meta: null,
      },
      test: [],
      now: 1,
      publish: "",
      type_actions:""
    };
  },
  computed: {
    disable_add_article() {
      if (!this.destin || this.destin == "") {
        return true;
      }

      if (!this.categories || this.categories == "") {
        return true;
      }

      if (
        !this.article ||
        this.article == "" ||
        (this.article && this.article.length < 4)
      ) {
        return true;
      }

      if (!this.slug || this.slug == "") {
        return true;
      }

      if (
        !this.description ||
        this.description == "" ||
        (this.description &&
          (this.description.length < 15 || this.description.length > 250))
      ) {
        return true;
      }

      if (!this.data || this.data == "") {
        return true;
      }

      if (!this.fichier || this.fichier == "" || this.fichier.length == 0) {
        return true;
      }

      return false;
    },
  },
  watch:{
      now(value){
          if(value == 1){
              this.publish = null
          }
      }
  },
  methods: {
      allowed_date(val){
          return val > new Date().toISOString()
      },
    showcircuits() {
    },
    set_image(data) {
      Axios.post(this.baseUrl + "imgeditor", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }).then((response) => {
        let range = this.quill.getSelection(true);
        this.quill.insertEmbed(
          range.index,
          "image",
          this.path + response.data.data
        );
        this.quill.setSelection(range.index + 1);
      });
    },
    image_handler(image) {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.click();

      // Listen upload local image and save to server
      input.onchange = () => {
        var file = input.files[0];

        // file type is only image.
        if (/^image\//.test(file.type)) {
          var formData = new FormData();
          formData.append("image", file);
          this.set_image(formData);
        } else {
          console.warn("You could only upload images.");
        }
      };
    },
    piece_jointe: function (event) {
      var input = event.target;

      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.fichier = [
            {
              file: e.target.result,
            },
          ];

          this.tmp_file.type = input.files[0].type.split("/")[0];
          this.tmp_file.path = e.target.result;
        };

        reader.readAsDataURL(input.files[0]);
      }
    },
    supprimer_piece_j(index) {
      this.fichier.splice(index, 1);
    },
    SendVideo() {
      if (
        this.video.titre === "" ||
        this.video.description === "" ||
        this.video.url === ""
      ) {
        this.$toaster.error("Champ obligatoire");
      } else {
        Axios.post(this.baseUrl + "SendVideo", {
          titre: this.video.titre,
          description: this.video.description,
          url: this.video.url,
        }).then((response) => {
          this.VideoArticle = response;
          this.$toaster.success("Vidéo enregistrée avec succès");
          this.video = {
            url: "",
            titre: "",
            description: "",
          };
          window.location.href = this.baseUrl + "blog";
        });
      }
    },

    sendblog() {
      Axios.post(this.baseUrl + "sendblog", {
        categories: this.categories,
        article: this.article,
        destin: this.destin,
        description: this.description,
        fichier: this.fichier,
        detail: this.document.querySelector(".ql-editor").innerHTML,
        seo_title: this.seo.title,
        seo_meta: this.seo.meta,
        slug: this.slug,
        now: this.now,
        publish:this.publish,
        type_actions:this.type_actions ="Create"
      })
        .then((response) => {
          this.blog = response;
          this.$toaster.success("Article enregistré avec succès");
          (this.categories = ""),
            (this.article = ""),
            (this.destin = ""),
            (this.circuits = []),
            (this.description = ""),
            (this.fichier = []),
            (this.detail = ""),
            (this.slug = "");
         window.location.href = this.baseUrl + "blog";
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message);
        });
    },

    association() {
      if (
        this.V_Aritcle === "" ||
        this.article === "" ||
        this.circuits === []
      ) {
        this.$toaster.error("Tous les champs sont obligatoires");
      } else {
          let id_circuits = []
          this.circuits.forEach(circuit  => id_circuits.push(circuit.id));
        Axios.post(this.baseUrl + "association", {
          id: this.V_Aritcle,
          article: this.article,
          circuits: this.circuits, id_circuits : id_circuits
        }).then((response) => {
          this.ListFusion = response.succ ? response.succ : this.ListFusion;
          this.$toaster.success("Action effectuée avec succès");
        }).catch(error => {
            console.log(error)
        });
        (this.article = ""), (this.id_video = "");
        this.circuits = "";
        // window.location.href = this.baseUrl + "blog";
      }
    },

      associate_circuit(){
          if (
              this.V_Aritcle === "" ||
              this.article === "" ||
              this.circuits === []
          ) {
              this.$toaster.error("Tous les champs sont obligatoires");
          } else {
              let id_circuits = []
              this.circuits.forEach(circuit  => id_circuits.push(circuit.id));
              Axios.post(this.baseUrl + "associationCircuit", {
                  article: this.article,
                  id_circuits : id_circuits
              }).then((response) => {
                  this.ListFusion = response.succ ? response.succ : this.ListFusion;
                  this.$toaster.success("Action effectuée avec succès");
              });
              this.article = ""
              this.circuits = "";
              window.location.href = this.baseUrl + "blog";
          }
      },
    reset() {
      this.action = "";
    },
    sendCategorie() {
      Axios.post(this.baseUrl + "sendCategorie", {
        title: this.title,
      }).then((response) => {
        this.cat = response;
      });
      this.title = "";
    },
    getAllCategorie() {
      Axios.get(this.baseUrl + "getAllCategories").then((response) => {
        this.listCategorie = response.data.categoriesArticle;
      });
    },
    getAllDestination() {
      Axios.get(this.baseUrl + "getAllDestination").then((response) => {
        this.listDestinations = response.data.destinations;
      });
    },
    getAllVideo() {
      Axios.get(this.baseUrl + "getAllVideo").then((response) => {
        this.listVideo = response.data.VideoArticle;
      });
    },

    updateblog() {
      /* Axios.post(baseUrl +"updateblog"/+ lb, {

      }).then((response) =>{

      })*/
    },

    getAllCircuits() {
      Axios.get(this.baseUrl + "getAllCircuits").then((response) => {
        this.litsCircuitCopy = response.data.copys;
      });
    },
    getAllblog() {
      Axios.get(this.baseUrl + "getAllblog").then((response) => {
        this.listBlog = response.data.blog;
      });
    },

    setQuill() {
      this.quill = this.$refs.myQuillEditor.quill;
    },

    uploadFile() {
      this.$refs.piece.click();
    },
  },

  mounted() {
    this.getAllCircuits();
    this.getAllDestination();
    this.getAllblog();
    this.getAllCategorie();
    this.getAllVideo();
    this.document = document;
  },
};
</script>

<style>
.file-zone {
  height: 350px;
}

.input-zone {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.input-zone i {
  font-size: 30px;
}

.input-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-button i {
  font-size: 45px;
}

.upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  cursor: pointer;
  transition-duration: 500ms;
}

.upload-button:hover {
  background-color: #5646ff;
  transition-duration: 500ms;
  color: white;
}

.piece-show {
  width: 100%;
  height: 100%;
}

.invalide-required .v-input__control .v-input__slot {
  border: 1px solid red !important;
}

.valide-required > .v-input__control > .v-input__slot {
  border: 1px solid green !important;
}
</style>
