require('./bootstrap');
import Vue from 'vue';
import VueRouter from 'vue-router';

import Toaster from 'v-toaster'
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'v-toaster/dist/v-toaster.css'
import vuetify from './plugins/vuetify';
import draggable from 'vuedraggable';
import VueTelInput from "vue-tel-input";
import 'vue-tel-input/dist/vue-tel-input.css';

import Vue2Editor from "vue2-editor";
Vue.use(VueRouter)
window.VueRouter = require('vue-router').default;

Vue.use(Vue2Editor);
// optional set default imeout, the default is 10000 (10 seconds).
Vue.use(Toaster, { timeout: 5000 })

Vue.use(draggable)


Vue.use(VueTelInput);

window.Vue = require('vue');
// window.axios = require('axios');

const Message = Vue.component('message', require('./components/Message.vue').default);
const Discussion = Vue.component('discussion', require('./components/discussion/discussion.vue').default);
const Blog = Vue.component('blog', require('./components/blog/blog.vue').default);
const UpdateBlog = Vue.component('update-blog', require('./components/blog/update-blog.vue').default);
const Categorie = Vue.component('categorie', require('./components/categorie/categorie.vue').default);
const Categories = Vue.component('categories', require('./components/categories/categories.vue').default);
const Chat = Vue.component('chat', require('./components/discussion/chat.vue').default);
const Slides = Vue.component('slides', require('./components/slides/slides.vue').default)
const Activite = Vue.component('activite', require('./components/activite/activite.vue').default)
const Infopratique = Vue.component('info-pratique', require('./components/destinations/infopratique.vue').default)
const Banniere = Vue.component('percentage', require('./components/banniere/index.vue').default)
const Percentage = Vue.component('application-percentage', require('./components/percentages/index.vue').default)
const Questionnaire = Vue.component('questionnaire', require('./components/questionnaire/index.vue').default)
const Slug = Vue.component('slug', require('./components/slug/index.vue').default)
const UpdateCircuits = Vue.component('update-tour', require('./components/updatecircuits/update-tour.vue').default)

const storage = {
    index: Vue.component('storage-index', require('./components/storage/index.vue').default),
    file: Vue.component('storage-file', require('./components/storage/file.vue').default),
    archive: Vue.component('storage-archive', require('./components/storage/archive.vue').default),
    filearchive: Vue.component('storage-file-archive', require('./components/storage/file-archive.vue').default),
    visualize: Vue.component('storage-visualize-file', require('./components/storage/visualize-file.vue').default),
    grillindex: Vue.component('storage-grill-index', require('./components/storage/grille-index.vue').default),
    grillarchive: Vue.component('storage-grill-archive', require('./components/storage/grille-archive.vue').default),
    listindex: Vue.component('storage-list-index', require('./components/storage/list-index.vue').default),
    listarchive: Vue.component('storage-list-archive', require('./components/storage/list-archive.vue').default),
}

const associatedestinationtaxe = Vue.component('associate-destination-taxe', () => import(
    /* webpackChunkName: "associate-destination-taxe" */
    './components/taxe/associate-destination.vue'
))

const gestiondossier = Vue.component('gestion-dossier', () => import(
    /* webpackChunkName: "gestion-dossier" */
    './components/dossier/index.vue'
))

const dialogdetaildossier = Vue.component('dialog-detail-dossier', () => import(
    /* webpackChunkName: "dialog-detail-dossier" */
    './components/dossier/dialog-detail.vue'
))

const detaildossier = Vue.component('detail-dossier', () => import(
    /* webpackChunkName: "detail-dossier" */
    './components/dossier/detail.vue'
))

const generaldossier = Vue.component('general-dossier', () => import(
    /* webpackChunkName: "general-dossier" */
    './components/dossier/general.vue'
))

const achatdossier = Vue.component('achat-dossier', () => import(
    /* webpackChunkName: "achat-dossier" */
    './components/dossier/achat.vue'
))

const ventedossier = Vue.component('vente-dossier', () => import(
    /* webpackChunkName: "vente-dossier" */
    './components/dossier/vente.vue'
))

const voyageurdossier = Vue.component('voyageur-dossier', () => import(
    /* webpackChunkName: "voyageur-dossier" */
    './components/dossier/voyageur.vue'
))

const piecedossier = Vue.component('piece-jointe-dossier', () => import(
    /* webpackChunkName: "piece-jointe-dossier" */
    './components/dossier/piece-jointe.vue'
))

const reservationdossier = Vue.component('reservation-dossier', () => import(
    /* webpackChunkName: "reservation-dossier" */
    './components/dossier/reservation.vue'
))

const formpaiementdossier = Vue.component('form-paiement-dossier', () => import(
    /* webpackChunkName: "form-paiement-dossier" */
    './components/dossier/form-paiement.vue'
))

const detailpaiementdossier = Vue.component('detail-paiement', () => import(
    /* webpackChunkName: "detail-paiement" */
    './components/dossier/detail-paiement.vue'
))

const devis = Vue.component('devis', () => import(
    /* webpackChunkName: "devis" */
    './components/devis/index.vue'
))

const motionscountry = Vue.component('video-country', () => import(
    /* webpackChunkName: "video-country" */
    './components/country/video-country.vue'
))

// const Historique = VUe.component('historique', require('./components/historique/historique.vue').default)

const Historique = Vue.component('historique', () => import(
    /* webpackChunkName: "historique" */
    './components/historique/historique.vue'
))

const hebergement = Vue.component('hebergement-dossier', () => import(
    /* webpackChunkName: "hebergement-dossier" */
    './components/dossier/hebergement.vue'
))

/* Formalité voyage */
const formalite = Vue.component('formalite-dossier', () => import(
    /* webpackChunkName: "hebergement-dossier" */
    './components/dossier/formalite.vue'
))

const formformalite = Vue.component('form-formalite-dossier', () => import(
    /* webpackChunkName: "hebergement-dossier" */
    './components/dossier/form-formalite.vue'
))

/* Formalité voyage end */

const formhebergement = Vue.component('form-hebergement-dossier', () => import(
    /* webpackChunkName: "form-hebergement-dossier" */
    './components/dossier/form-hebergement.vue'
))

const detailhebergement = Vue.component('detail-hebergement-dossier', () => import(
    /* webpackChunkName: "detail-hebergement-dossier" */
    './components/dossier/detail-hebergement.vue'
))

const assurance = Vue.component('assurance-dossier', () => import(
    /* webpackChunkName: "assurance-dossier" */
    './components/dossier/assurance.vue'
))

const formassurance = Vue.component('form-assurance-dossier', () => import(
    /* webpackChunkName: "form-assurance-dossier" */
    './components/dossier/form-assurance.vue'
))

Vue.component('detail-formality-dossier', () => import(
    /* webpackChunkName: "form-assurance-dossier" */
    './components/dossier/detail-formality.vue'
))
Vue.component('delete-formality-dossier', () => import(
    /* webpackChunkName: "form-assurance-dossier" */
    './components/dossier/modal-delete-formality.vue'
))

Vue.component('add-type-assurance', () => import(
    /* webpackChunkName: "form-assurance-dossier" */
    './components/assurance/add-type.vue'
))

Vue.component('form-reservation-dossier', () => import(
    /* webpackChunkName: "form-assurance-dossier" */
    './components/dossier/form-reservation.vue'
))


const detailassurance = Vue.component('detail-assurance-dossier', () => import(
    /* webpackChunkName: "detail-assurance-dossier" */
    './components/dossier/detail-assurance.vue'
))

const transport = Vue.component('transport-dossier', () => import(
    /* webpackChunkName: "transport-dossier" */
    './components/dossier/transport.vue'
))

const formtransport = Vue.component('form-transport-dossier', () => import(
    /* webpackChunkName: "form-transport-dossier" */
    './components/dossier/form-transport.vue'
))

const detailtransport = Vue.component('detail-transport-dossier', () => import(
    /* webpackChunkName: "detail-transport-dossier" */
    './components/dossier/detail-transport.vue'
))

const deletehorscircuit = Vue.component('modal-delete-horscircuit', () => import(
    /* webpackChunkName: "modal-delete-horscircuit" */
    './components/dossier/modal-delete-horscircuit.vue'
))

const modalnewdossier = Vue.component('modal-new-folder', () => import(
    /* webpackChunkName: "modal-new-folder" */
    './components/dossier/modal-new-folder.vue'
))

const modalnewtraveler = Vue.component('form-voyageur', () => import(
    /* webpackChunkName: "form-voyageur" */
    './components/dossier/form-voyageur.vue'
))
const Mailing = Vue.component('mailing', () => import(
    /* webpackChunkName: "mailing" */
    './components/mailing/mailing.vue'
))
const Update = Vue.component('update', () => import(
    /* webpackChunkName: "update-mailing" */
    './components/mailing/update.vue'
))

const Tagcircuit = Vue.component('tags-circuit', () => import(
    /* webpackChunkName: "tags-circuit" */
    './components/tagcircuit/tags-circuit.vue'
))

const udpatetranche = Vue.component('update-tranche', () => import(
    /* webpackChunkName: "update-tranche" */
    './components/dossier/update-tranche.vue'
))

Vue.component('responsable-dossier', () => import(
    './components/dossier/responsable.vue'
))

Vue.component('form-reponsable-dossier', () => import(
    './components/dossier/form-responsable.vue'
))

Vue.component('condition-annulation-dossier', () => import(
    './components/dossier/condition-annulation.vue'
))

Vue.component('form-condition-annulation-dossier', () => import(
    './components/dossier/form-condition-annulation.vue'
))



Vue.component('photoreporter', () => import(
    './components/photoreporter/photoreporter.vue'
))

Vue.component('photoreporter-visualiser', () => import(
    './components/photoreporter/visualiser.vue'
))


// Vue.prototype.$baseurl = "https://admin.noah-voyages.com";
Vue.prototype.$baseurl = "localhost/noah-voyages-api/";

window.router = new VueRouter({
    routes: [{
        path: '/admin/message',
        name: 'message',
        component: Message,
    }, {
        path: '/admin/privée',
        name: 'discussion',
        component: Discussion
    },
    {
        path: '/admin/privée/chat',
        name: 'chat',
        component: Chat
    },
    {
        path: '/admin/blog',
        name: 'blog',
        component: Blog
    },
    {
        path: '/admin/categories',
        name: 'categorie',
        component: Categorie
    },
    {
        path: '/admin/slides',
        name: 'slides',
        component: Slides
    },
    {
        path: '/admin/activites',
        name: 'activite',
        component: Activite
    },
    {
        path: '/admin/categorie',
        name: 'categories',
        component: Categories
    },
    ],
})

const app = new Vue({
    router,
    vuetify,
    el: '#appVue',
    components: {
        'update-blog': UpdateBlog,
        'info-pratique': Infopratique,
        'banniere': Banniere,
        'percentages': Percentage,
        'questionnaire': Questionnaire,
        'slug': Slug,
        'update-tour': UpdateCircuits,
        'storage-index': storage.index,
        'storage-file': storage.file,
        'storage-archive': storage.archive,
        'storage-file-archive': storage.filearchive,
        'storage-visualize-file': storage.visualize,
        'storage-grill-index': storage.grillindex,
        'storage-grill-archive': storage.grillarchive,
        'storage-list-index': storage.listindex,
        'storage-list-archive': storage.listarchive,
        'gestion-taxe': () => import(
            /* webpackChunkName: "gestion-taxe" */
            './components/taxe/index.vue'
        ),
    }
});

window.dynamiqueIdNewView = (el) => {
    new Vue({
        router,
        vuetify,
        el: '#' + el,
    });
}