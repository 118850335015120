<template>
    <div class="card">
        <div class="card-body">
            <h4>ACTIVITES</h4>
            <div class="tab-content">
                    <form @submit.prevent="create_activite()"
                    enctype="multipart/form-data">
                            <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                <div class="form-group">
                                    <label for="my-input">Titre d'activité :</label>
                                    <input class="form-control" v-model="nom_activite" type="text" placeholder="Titre activite"/>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-4 col-xs-4 col-sm-4">
                                        <button type="submit" class="btn-save-dest btn btn-primary">Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                    </form>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import Axios from "axios";
import Toaster from "v-toaster";
import "v-toaster/dist/v-toaster.css";

export default {
  data() {
    return {
      baseUrl: window.baseUrl + 'admin/',
      nom_activite: "",
      activites: "",
    };
  },
  methods: {

    create_activite() {
      if(this.nom_activite === '' || this.nom_activite.length<5){
        this.$toaster.error("Champ obligatoire avec 5 caractères au moins")
      }else{
         Axios.post(this.baseUrl + "create_activite", {
        nom_activite: this.nom_activite,
      }).then((response) => {
        this.activites = response;
      });
      (this.nom_activite = "")
      this.$toaster.success('Activité enregistrée avec succès')
      window.location.reload()
      }
    },
    
  },

  mounted() {
    
  },
};
</script>
