<template>
  <div class="container">
    <h3 class="text-center">Chat</h3>
    <div class="messaging">
      <div class="inbox_msg">
        <div class="inbox_people">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="conversationLibre-tab"
                data-toggle="tab"
                href="#conversationLibre"
                role="tab"
                aria-controls="conversationLibre"
                aria-selected="true"
                @click="affiche()"
                >En attente
                <span class="badge badge-custome">{{
                  listMessagesLibreNonLue
                }}</span></a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="convesationNow-tab"
                data-toggle="tab"
                href="#convesationNow"
                role="tab"
                aria-controls="convesationNow"
                aria-selected="false"
                @click="affiche()"
                >En cours
                <span class="badge badge-custome">{{
                  listMessagesEnCourNonLue
                }}</span></a
              >
            </li>
            <li class="nav-item" v-if="listDiscussionT != null">
              <a
                class="nav-link"
                id="convesationTerminer-tab"
                data-toggle="tab"
                href="#convesationTerminer"
                role="tab"
                aria-controls="convesationTerminer"
                aria-selected="false"
                @click="terminer()"
                >Terminé</a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="conversationLibre"
              role="tabpanel"
              aria-labelledby="conversationLibre-tab"
            >
              <div class="inbox_chat">
                <div
                  class="chat_list active_chat"
                  v-for="(listMessage, lp) in listMessagesLibre"
                  :key="lp"
                  v-bind:class="{ non_lue: listMessage.length > 0 && listMessage.message[0].status == 0 }"
                >
                  <div @click="getId(listMessage)" class="chat_people">
                    <div class="chat_img">
                      <img
                        class="avat"
                        src="https://bootdey.com/img/Content/avatar/avatar1.png"
                        alt=""
                      />
                    </div>
                    <div class="chat_ib" v-if="listMessage.message.length > 0"> 
                      <h4> {{listMessage.client != null?listMessage.client.nom + ' ' +listMessage.client.prenom : 'Invite '+ listMessage.token_id.substr(0,4)}} </h4>
                      <h5>
                        {{listMessage.message[0].message}} <br /><br />
                        &nbsp;&nbsp;<span class="chat_date">
                          {{
                              new Date(listMessage.message[0].created_at)
                                | dateFormat("DD MMMM YYYY  h:mm")
                            }}</span
                        >
                      </h5>
                    </div>
                    <div v-else><h5>Aucun message</h5></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="convesationNow"
              role="tabpanel"
              aria-labelledby="convesationNow-tab"
            >
              <div class="inbox_chat">
                <div
                  class="chat_list active_chat"
                  v-for="(listMessage, lp) in listMessages"
                  :key="lp"
                  v-bind:class="{ non_lue: listMessage.message ? listMessage.message[0].status == 0 : '' }"
                >
                  <div @click="getId(listMessage)" class="chat_people">
                    <div class="chat_img">
                      <img
                        class="avat"
                        src="https://bootdey.com/img/Content/avatar/avatar1.png"
                        alt=""
                      />
                    </div>
                    <div class="chat_ib" v-if="listMessage.message.length > 0">
                      <h4> {{listMessage.client != null?listMessage.client.nom + ' ' +listMessage.client.prenom : 'Invite '+ listMessage.token_id.substr(0,4)}} </h4>
                      <h5>
                        {{ listMessage.message[0].message}} <br /><br />
                        &nbsp;&nbsp;<span class="chat_date">
                          {{
                              new Date(listMessage.message[0].created_at)
                                | dateFormat("DD MMMM YYYY  h:mm")
                          }}</span
                        >
                      </h5>
                    </div>
                    <div v-else><h5>Aucun message</h5></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="convesationTerminer"
              role="tabpanel"
              aria-labelledby="convesationTerminer-tab"
            >
              <div class="inbox_chat">
                <div
                  class="chat_list active_chat"
                  v-for="(listDiscussion, ld) in listDiscussionT"
                  :key="ld"
                >
                  <div
                    @click="getId(listDiscussion)"
                    class="chat_people"
                  >
                    <div class="chat_img">
                      <img
                        class="avat"
                        src="https://bootdey.com/img/Content/avatar/avatar1.png"
                        alt=""
                      />
                    </div>
                    <div class="chat_ib" v-if="listDiscussion.length >0">
                      <h4> {{listDiscussion.client != null?listDiscussion.client.nom + ' ' +listDiscussion.client.prenom : 'Invite '+ listDiscussion.token_id.substr(0,4)}} </h4>
                      <h5>
                        {{listDiscussion.message[0].message}} <br /><br />
                        &nbsp;&nbsp;<span class="chat_date">
                         {{
                              new Date(listMessage.message[0].created_at)
                                | dateFormat("DD MMMM YYYY  h:mm")
                            }}</span
                        >
                      </h5>
                    </div>
                    <div v-else><h5>Aucun message</h5></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mesgs" v-if="conversations.length != 0">
          <div class="row optionconversation" style="margin-top:-30px">
            <div class="col-md-5 transRepondeur">
              <span for="">Transférer à :</span>
              <select ref="user_id" class="transfer">
                <option v-for="(user, tp) in users" :key="tp" :value="user.id">
                  {{ user.name }}
                </option>
              </select>
              <button class="btn btn-help" ref="button" style="margin-left:10px">
                <i style="margin-left: -20px;"
                  class="fa fa-paper-plane-o transfer"
                  aria-hidden="true"
                  @click="transfer()"
                ></i>
              </button>
            </div>
            <div class="col-md-7" style="display: inline-flex;margin-top:;">
      
            <h5 style="margin-top: 18px;margin-left: -30px;">{{ClientName}}</h5>
      
            <button
              class="btn btn-help btn_del terminerConversation"
              ref="button"
              style="margin-top:;"
              @click="del()"
            >
              Terminer la discussion
              <i class="fa fa-times"></i>
            </button>
            </div>
          </div>
          <div class="msg_history">
            <div class="card-body height3">
              <div v-for="(conversation, lp) in conversations" :key="lp">
                  <ul class="chat-list">
                    <li v-bind:class="{in:conversation.sender_id != current_user.id,out:conversation.sender_id == current_user.id}">
                      <div class="chat-img">
                        <img
                          alt="Avtar"
                          src="https://bootdey.com/img/Content/avatar/avatar1.png"
                        />
                      </div>
                      <div class="chat-body">
                        <div class="chat-message">
                          <h5>{{ conversation.message }}</h5>
                          <p>
                            {{
                              new Date(conversation.created_at)
                                | dateFormat("DD MMMM YYYY  h:mm")
                            }}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
              </div>
            </div>
          </div>

          <div id="success">
            <form @submit.prevent="sendMessage">
              <div class="type_msg">
                <div class="input_msg_write">
                  <textarea
                    type="text"
                    v-model="message_corps"
                    @keyup.enter="sendMessage"
                    class="write_msg"
                    ref="message_body"
                    placeholder="Tapez votre message"
                    autocomplete="off"
                    name="message"
                  />
                  <button
                    class="msg_send_btn envoyer"
                    type="submit"
                    id="envoyer"
                  >
                    <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Axios from "axios";
import VueFilterDateFormat from "vue-filter-date-format";
import moment from 'moment'

Vue.use(VueFilterDateFormat, {
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
});
export default {
  data() {
    return {
      conversations: [],
      messageData: [],
      listMessages: [],
      listMessagesLibre: [],
      message_corps: "",
      chat_message_id: "",
      users: [],
      current_user: "",
      listDiscussionT: [],
      listTotalMessagesNonLue: "",
      listMessagesEnCourNonLue: "",
      listMessagesLibreNonLue: "",
      ClientName:null
    };
  },

  methods: {
    format: function(value){
    return moment(String(value)).format('MM DD YYYY hh:mm')
    },
    getId(listDiscussion) {
      let m = listDiscussion ? listDiscussion.message : ''
      this.conversations = m.slice().reverse();
      this.chat_message_id = m[0].chat_messages_id;
      this.ClientName = listDiscussion.client != null?listDiscussion.client.nom + ' ' +listDiscussion.client.prenom:'Inviter '+listDiscussion.token_id.substr(0,4)
      Axios.get("conversation/" + this.chat_message_id + "")
        .then(function (data) {
            this.Updatestat();
           document.getElementsByClassName('msg_history')[0].scrollBy(0,99999999999999)
        })
        .catch(function (error) {
          console.log(error);
        });
      
    },
    getUser() {
      Axios.get("getUser/")
        .then((response) => {
          this.users = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getCurrentUser() {
      Axios.get("user")
        .then((response) => {
          this.current_user = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
     Updatestat() {
      Axios.get("Updatestat/" + this.chat_discussion_id + "")
        .then(function (data) {
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    transfer() {
      Axios.post("updateUser/" + this.chat_message_id + "", {
        user_id: this.$refs.user_id.value,
      })
        .then(function (data) {
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    sendMessage() {
      this.message_corps = this.$refs.message_body.value;
      Axios.post("message/sendMessage", {
        message: this.message_corps,
        chat_message_id: this.chat_message_id,
      }).then((response) => {
            // this.conversations = response.data.succ.slice().reverse();
          this.getMessage()
            this.message_corps = "";
      });
      
    },

    getMessage() {
      Axios.get("getMessage").then((response) => {
        this.listMessages = response.data.messages;
        this.listMessagesLibre = response.data.messagesLibre;
        this.listDiscussionT = response.data.discussionTerminer;
         document.getElementsByClassName('msg_history')[0].scrollBy(0,99999999999999)
      });
      this.getStat();
    //   this.getId()
    },
    getStat() {
      Axios.get("getStat").then((response) => {
        this.listMessagesLibreNonLue = response.data.messageLibre;
        this.listMessagesEnCourNonLue = response.data.messageEnCours;
      });
    },
    playSound() {
      let sound =
        "http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3";
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },

    del() {
      Axios.post("updateToken/", {
        chat_message_id: this.chat_message_id,
      })
        .then(function (data) {
        })
        .catch(function (error) {
          console.log(error);
        });
      // alert("La conversation est terminer");
    },
    terminer(){
      document.getElementsByClassName('optionconversation')[0].style.visibility='hidden';
      document.getElementsByClassName('type_msg')[0].style.visibility='hidden'
    },
   affiche(){
       document.getElementsByClassName('optionconversation')[0].style.visibility='visible';
      document.getElementsByClassName('msg_history')[0].style.visibility='visible';
      document.getElementsByClassName('type_msg')[0].style.visibility='visible'
    }

  },


  mounted() {
      
    this.getMessage();
    this.getCurrentUser();
    this.getUser();

    var channel = Echo.channel("chat")
    var obj = this
    channel.listen(".NewChatMessage", function(e){

      if (e.message != null) {
        if (obj.chat_message_id == e.chat_message) {
            obj.conversations.push({
                message: e.message,
                sender_id: e.sender_id,
                chat_message_id: e.chat_message,
                created_at: new Date(),
            });
        }
        if (e.sender_id != obj.current_user.id) {
          obj.playSound();
        }

      }else{
        obj.conversations = []
      }
      obj.getMessage();
    });
  },
};
</script>
