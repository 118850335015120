<template>
    <v-dialog
        v-model="modal"
        min-width="500"
        min-height="500"

        max-width="1080"
        max-height="760"

        @click:outside="close()"
        @keydown="close()"
    >
        <v-card v-if="files">
            <v-card-title style="width: 100%">
                {{ files.filename }}
            </v-card-title>

            <v-card-text>
                <v-img
                    v-if="isType('image')"
                    width="100%"
                    height="auto"
                    :src="files.fullurl"
                ></v-img>

                <video v-if="isType('video')" width="100%" height="auto" controls autoplay>
                    <source :src="files.fullurl">
                </video>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {

        name: 'storage-visualize-file',

        props: {
            files: Object,
            default: null,
        },

        data(){
            return {
                currentPage: 1,
                pageCount: 1,

                type: {
                    image: ['jpeg', 'jpg', 'png', 'gif', 'jiff', 'svg', 'bmp'],
                    video: ['mp4', 'mkv'],
                }
            }
        },

        computed: {
            modal(){
                return this.files ? true : false
            }
        },

        watch: {
            files: {
                handler(val){
                    if(val){
                        if(!this.isType('image') && !this.isType('video')){
                            window.open(this.files.fullurl, '_blank')
                            this.close()
                        } 
                    }
                },
                immediate:true
            }
        },

        methods: {
            close(){
                this.$emit('close')
            },

            isType(type){
                switch(type){
                    case 'image' : return this.type.image.includes(this.getExtension(this.files.filename))
                        break;
                    case 'video': return this.type.video.includes(this.getExtension(this.files.filename))
                        break;
                }
            },

            getExtension(file){
                return file.split('.')[1]
            }
        },
        
        destroyed(){
            console.log('destroy')
        }

    }

</script>