<template>
    <v-app>
        <v-card flat>
            <v-card-title>
                Gestion de Fichier
            </v-card-title>

            <v-card-text>
                <v-tabs
                    v-model="tab"
                    fixed-tabs
                    background-color="#00A2A1"
                    dark
                    center-active
                    show-arrows
                >
                    <v-tabs-slider color="#F7941D"></v-tabs-slider>

                    <v-tab>
                        Circuits cover
                    </v-tab>

                    <v-tab>
                        Circuits hosts
                    </v-tab>

                    <v-tab>
                        Circuits visites
                    </v-tab>

                    <v-tab>
                        Guides
                    </v-tab>

                    <v-tab>
                        Destinations
                    </v-tab>

                    <v-tab>
                        Slides
                    </v-tab>

                    <v-tab>
                        Thématiques
                    </v-tab>

                    <v-tab>
                        Maps continents
                    </v-tab>

                    <v-tab>
                        Backgrounds continents
                    </v-tab>

                    <v-tab>
                        Country raison
                    </v-tab>

                    <v-tab>
                        Country icons
                    </v-tab>

                    <v-tab>
                        Country flags
                    </v-tab>

                    <v-tab>
                        Country Motions
                    </v-tab>

                    <v-tab>
                        Banniers
                    </v-tab>

                    <v-tab>
                        Articles
                    </v-tab>

                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 0 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="circuits" labelName="Circuits" headerModal="covers circuits"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 1 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="hosts" labelName="Hosts" headerModal="hosts"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 2 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="visites" labelName="Visites" headerModal="visites"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 3 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="guides" labelName="Guide" headerModal="guides"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 4 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="destinations" labelName="Destinations" headerModal="destinations"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 5 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="slides" labelName="Slides" headerModal="slides"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 6 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="thematiques" labelName="Thématiques" headerModal="thématiques"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 7 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="continents/maps" labelName="Map" headerModal="maps continent"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 8 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="continents/background" labelName="Background" headerModal="background continent"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 9 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="whys" labelName="Titre" headerModal="raison de visiter"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 10 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="country_icons" labelName="Country" headerModal="icons country"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 11 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="country_flags" labelName="Country" headerModal="flags country"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftpvideo" :headers="headers" @termined="termined = false" :selected="tab == 12 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="motions" labelName="Country" headerModal="vidéo motions country"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 13 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="banners" labelName="Titre" headerModal="bannières"></storage-file>
                    </v-tab-item>

                    <v-tab-item>
                        <storage-file type="ftp" :headers="headers" @termined="termined = false" :selected="tab == 14 ? true : false" :is_loading="termined" @isdelete="confirmdelete" model="articles" labelName="Titre" headerModal="articles"></storage-file>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>

        <v-dialog
            v-model="modal"
            max-width="680"
            persistent
        >
            <v-overlay
                :value="loading"
            >
                <v-progress-circular
                    indeterminate
                    color="white"
                    :size="50"
                ></v-progress-circular>
            </v-overlay>

            <v-card v-if="item">
                <v-card-title>
                    Voulez vous vraiment archiver les fichiers suivants dans {{ item.header + ' ?'}}
                </v-card-title>

                <v-form @submit.prevent="executeDelete()">
                    <v-card-text>
                        <div>
                            {{ toDelete }}
                        </div>

                        <div>
                            <v-checkbox
                                v-model="destroy"
                                :value="true"
                                :true-value="true"
                                :false-value="false"
                            >
                                <template v-slot:label>
                                    Supprimer définitivement
                                </template>
                            </v-checkbox>
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            class="mr-2"
                            color="primary"
                            @click="close()"
                        >
                            Annuler
                        </v-btn>

                        <v-btn
                            class="mr-2"
                            color="success"
                            type="submit"
                        >
                            Confirmer
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<style>
    .v-tab {
        text-transform: none !important;
        min-width: 170px;
        max-width: 170px;
    }

    .v-tabs-slider-wrapper{
        min-width: 170px;
        max-width: 100%;
    }
</style>

<script>
    import Axios from 'axios';

    export default {
        name: 'storage-index',
        props: ['authorization'],
        data(){
            return {
                baseUrl: window.baseUrl + 'api/admin/storage/',
                tab: null,
                loading: false,
                termined: false,
                modal: false,
                item: {
                    storage: [],
                    model: null,
                    header: null
                },

                destroy: false
            }
        },
        computed: {
            headers(){
                return {
                    isadmin: this.authorization
                }
            },

            toDelete(){
                var list = '';

                this.item.storage.forEach(element => {
                    if(list){
                        list = list + ', ' + element.filename
                    }else{
                        list = element.filename
                    }
                });

                return list
            }
        },

        methods: {
            confirmdelete(item){
                this.item = {
                    storage: item.storage,
                    model: item.model,
                    header: item.header,
                    type: item.type
                }

                this.modal = true;
            },

            executeDelete(){
                this.loading = true;

                let data = {
                    fichier: this.item.storage,
                    type: this.item.type
                }

                try{
                    let action = this.destroy ? 'delete' : 'archive';
                    Axios.post(this.baseUrl + action, data, {
                        headers: {
                            isadmin: this.authorization
                        }
                    }).then(response => {
                        if(response.data.success){
                            this.$toaster.success(response.data.success);
                        }

                        if(response.data.error){
                            this.$toaster.error("Une erreur est survenue, veuillez réessayer");
                            console.log('error delete', response.data.error)
                        }
                    }).catch(error => {
                        this.$toaster.error("Une erreur est survenue, veuillez réessayer \n " + error.message);
                        console.log('error server', error)
                    }).finally(() => {
                        this.loading = false;
                        this.termined = true;
                        this.destroy = false
                        this.close()
                    })
                }catch(error){
                    this.$toaster.error("Une erreur est survenue, veuillez réessayer \n " + error.message);
                    console.log('error server', error)
                }
            },

            close(){
                this.modal = false;
                this.item = {
                    storage: [],
                    model: null,
                    header: null
                };
            }
        }
    }
</script>
