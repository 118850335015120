<template>
	<div>
		<!-- a propos form -->
		<div class="card">
			<div class="card-header">A propos</div>

			<div class="card-body">
				<input
					type="text"
					v-model="about.title"
					class="form-control"
					placeholder="Example: A propos d'Arménie"
				/>
				<div class="card mt-2">
					<div class="card-header">Contenus</div>
					<div class="card-body">
						<div
							class="row"
							v-for="(content, i) in about.contents"
							:key="'content' + i"
						>
							<div class="col-md-5">
								<input
									type="text"
									v-model="about.contents[i].title"
									class="form-control"
									placeholder="Titre (Example: Population)"
								/>
							</div>

							<div class="col-md-5">
								<input
									type="text"
									v-model="about.contents[i].value"
									class="form-control"
									placeholder="Valeur (Example: 2 200 000)"
								/>
							</div>

							<div class="md-2 d-flex align-items-center">
								<div class="row">
									<div
										class="col-md-6"
										v-if="
											about.contents.length > 1 || about.contents[i].id
										"
									>
										<v-btn
											class="mx-2"
											fab
											dark
											small
											color="#FF0000"
											@click="remove_about_content(i)"
										>
											<v-icon dark> mdi-minus </v-icon>
										</v-btn>
									</div>

									<div class="col-md-6" v-if="i == about.contents.length - 1">
										<v-btn
											class="mx-2"
											fab
											dark
											small
											color="#F7941D"
											@click="add_about_content()"
										>
											<v-icon dark> mdi-plus </v-icon>
										</v-btn>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<button
					@click="valider_about()"
					v-if="about.valide"
					class="btn mt-2"
					style="background-color: #00a2a1; color: white"
				>
					Valider
				</button>
			</div>
		</div>

		<!-- info pratique form -->
		<div class="card mt-2">
			<div class="card-header">Informations pratiques</div>

			<div class="card-body">
				<div
					class="card mb-2"
					v-for="(infopratique, i) in infopratiques.contents"
					:key="'infopratique' + i"
				>
					<div class="card-header">
						<div class="row">
							<div class="col-md-3 d-flex align-items-center">
								Information : {{ i + 1 }}
							</div>
							<div class="col-md-9">
								<input
									type="text"
									class="form-control"
									placeholder="Titre information (Example: Visa en Arménie)"
									v-model="infopratiques.contents[i].title"
								/>
							</div>
						</div>
					</div>

					<draggable
						v-model="infopratique.contents"
						group="people"
						@start="drag = true"
						@end="drag = false"
					>
						<div
							class="card-body"
							v-for="(content, j) in infopratique.contents"
							:key="'content' + j"
						>
							<input
								type="text"
								class="form-control mb-2"
								placeholder="Titre contenu (Example: Comment est le Visa en Arménie ?)"
								v-model="infopratiques.contents[i].contents[j].title"
							/>
							<textarea
								class="form-control"
								rows="5"
								placeholder="Description"
								v-model="infopratiques.contents[i].contents[j].description"
							></textarea>
							<div class="d-flex align-items-center justify-content-end mt-2">
								<div v-if="infopratique.contents.length">
									<v-btn
										class="mx-2"
										fab
										dark
										small
										color="#FF0000"
										@click="remove_info_content(i, j)"
									>
										<v-icon dark> mdi-minus </v-icon>
									</v-btn>
								</div>

								<div v-if="j == infopratique.contents.length - 1">
									<v-btn
										class="mx-2"
										fab
										dark
										small
										color="#F7941D"
										@click="add_info_content(i, j)"
									>
										<v-icon dark> mdi-plus </v-icon>
									</v-btn>
								</div>
							</div>
						</div>
					</draggable>

					<div class="card-footer">
						<div class="d-flex align-items-center justify-content-start">
							<div
								v-if="
									infopratiques.contents.length > 1 ||
									infopratiques.contents[i].id
								"
							>
								<v-btn
									class="mx-2"
									fab
									dark
									small
									color="#FF0000"
									@click="remove_info_pratique(i)"
								>
									<v-icon dark> mdi-minus </v-icon>
								</v-btn>
							</div>

							<div v-if="i == infopratiques.contents.length - 1">
								<v-btn
									class="mx-2"
									fab
									dark
									small
									color="#F7941D"
									@click="add_info_pratique(j)"
								>
									<v-icon dark> mdi-plus </v-icon>
								</v-btn>
							</div>

							<div class="col-md-2" v-if="valid_info(infopratique)">
								<v-btn
									class="mx-2"
									fab
									dark
									small
									color="#00A2A1"
									@click="valider_info_pratique(i)"
								>
									<v-icon dark> mdi-check </v-icon>
								</v-btn>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Axios from "axios";

import draggable from "vuedraggable";

export default {
	components: {
		draggable,
	},
	name: "info-pratique",
	props: {
		destination: Object,
	},
	data() {
		return {
			base_url: window.baseUrl + "admin/",
			about: {
				id_type: null,
				title: null,
				contents: [
					{
						title: null,
						value: null,
					},
				],

				valide: false,
			},

			infopratiques: {
				contents: [
					{
						title: null,
						contents: [
							{
								title: null,
								description: null,
							},
						],
					},
				],
				valide: false,
			},
		};
	},

	computed: {},

	watch: {
		about: {
			handler(value) {
				var valid_title = value.title && value.title.length > 5 ? true : false;
				var valid_content = value.contents.findIndex(
					(item) => !item.title || !item.value
				);
				this.about.valide = valid_title && valid_content == -1 ? true : false;
			},
			deep: true,
			immediate: true,
		},

		destination: {
			handler(value) {
				if (value.abouts) {
					this.about = value.abouts;
					this.about.contents.push({
						title: null,
						description: null,
					});
				}
				if (value.infos && value.infos.length > 0) {
					this.infopratiques.contents = value.infos;
					this.infopratiques.contents.push({
						title: null,
						contents: [
							{
								title: null,
								description: null,
							},
						],
					});
				}
			},
			immediate: true,
		},
	},

	methods: {
		valid_info_contents(content) {
			let valid = content.findIndex((item) => !item.title || !item.description);
			return valid == -1 ? true : false;
		},

		valid_info(content) {
			var valid_title = content.title ? true : false;
			var valid_content = this.valid_info_contents(content.contents);

			return valid_title && valid_content ? true : false;
		},

		add_about_content() {
			this.about.contents.push({
				title: null,
				value: null,
			});
		},

		remove_about_content(i) {
			if (this.about.contents[i].id) {
				if (confirm("Supprimer ce contenu ?")) {
					Axios.post(
						this.base_url +
							"destinations/about/delete/" +
							this.about.contents[i].id
					).then((response) => {
						if (response.data.error) {
							this.$toaster.error("Une erreur est survenue");
						}

						if (response.data.success) {
							this.$toaster.success("Mis à jour de la destination réussi");
							this.about.contents.splice(i, 1);
							window.location.reload();
						}
					});
				}
			} else {
				this.about.contents.splice(i, 1);
			}
		},

		valider_about() {
			Axios.post(this.base_url + "destinations/about", {
				...this.about,
				id_type: this.destination.id,
				type: "Destinations",
			}).then((response) => {
				if (response.data.warning || response.data.error) {
					this.$toaster.error("Une erreur est survenue");
				}

				if (response.data.success) {
					this.$toaster.success("Mis à jour de la destination réussi");
					window.location.reload();
				}
			});
		},

		add_info_pratique(i) {
			this.infopratiques.contents.push({
				title: null,
				contents: [
					{
						title: null,
						description: null,
					},
				],
			});
		},

		remove_info_pratique(i) {
			if (this.infopratiques.contents[i].id) {
				if (confirm("Supprimer ce contenu ?")) {
					Axios.post(
						this.base_url +
							"destinations/infopratique/delete/" +
							this.infopratiques.contents[i].id
					).then((response) => {
						if (response.data.error) {
							this.$toaster.error("Une erreur est survenue");
						}

						if (response.data.success) {
							this.$toaster.success("Mis à jour de la destination réussi");
							this.about.contents.splice(i, 1);
							window.location.reload();
						}
					});
				}
			} else {
				this.infopratiques.contents.splice(i, 1);
			}
		},

		add_info_content(i, j) {
			this.infopratiques.contents[i].contents.push({
				title: null,
				description: null,
			});
		},

		remove_info_content(i, j) {
			if (this.infopratiques.contents[i].contents[j].id) {
				if (confirm("Supprimer ce contenu ?")) {
					Axios.post(
						this.base_url +
							"destinations/infopratique/content/delete/" +
							this.infopratiques.contents[i].contents[j].id
					).then((response) => {
						if (response.data.error) {
							this.$toaster.error("Une erreur est survenue");
						}

						if (response.data.success) {
							this.$toaster.success("Mis à jour de la destination réussi");
							this.infopratiques.contents[i].contents.splice(j, 1);
						}
					});
				}
			} else {
				this.infopratiques.contents[i].contents.splice(j, 1);
			}
		},

		valider_info_pratique(i) {
			Axios.post(this.base_url + "destinations/infopratique", {
				infopratiques: this.infopratiques.contents[i],
				id: this.destination.id,
				type: "Destinations",
			}).then((response) => {
				if (response.data.warning || response.data.error) {
					this.$toaster.error("Une erreur est survenue");
				}

				if (response.data.success) {
					this.$toaster.success("Mis à jour de la destination réussi");
					window.location.reload();
				}
			});
		},

		cancel_form_about() {
			// this.about = this.about_original
		},
	},
};
</script>
