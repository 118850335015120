<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="cyan darken-2"
        ></v-progress-linear>

        <div class="d-flex justify-content-between">
            <v-pagination
                v-if="lists.length > 0"
                v-model="pagination.current"
                :length="pagination.count"
                :total-visible="10"
            ></v-pagination>

            <v-select
                v-if="lists.length > 0"
                :items="optionsPage"
                value="value"
                item-text="label"
                solo
                v-model="pagination.totalItems"
                class="select-total"
            ></v-select>
        </div>

        <v-checkbox
            v-if="lists.length > 0"
            label="Séléctionner tous les fichiers"
            value="check"
            true-value="check"
            :false-value="null"
            :input-value="selectall"
            @change="checkAll"
        >
        </v-checkbox>
        <v-row>
            <v-col v-for="item in toShows" :key="item.realpath" cols class="d-flex justify-content-center align-items-center">
                <v-hover v-slot="{ hover }">
                    <v-sheet
                        color="teal"
                        elevation="10"
                        height="250"
                        width="250"
                        class="position-relative"
                    >   
                        <div class="float-action d-flex">
                            <v-btn
                                :disabled="loading"
                                class="mx-2"
                                fab
                                dark
                                small
                                color="default"
                                title="Voir"
                                @click="visualize(item)"
                            >
                                <v-icon dark>
                                    mdi-eye
                                </v-icon>
                            </v-btn>

                            <v-btn
                                :disabled="loading"
                                class="mx-2"
                                fab
                                dark
                                small
                                color="primary"
                                title="Sélectionner"
                                @click="checkItem(isChecked(item) ? false : true, item)"
                            >
                                <v-icon v-if="isChecked(item)" dark>
                                    mdi-check-circle-outline
                                </v-icon>

                                <v-icon v-if="!isChecked(item)" dark>
                                    mdi-checkbox-blank-circle-outline
                                </v-icon>
                            </v-btn>

                            <v-btn
                                :disabled="loading"
                                class="mx-2"
                                fab
                                dark
                                small
                                color="error"
                                title="Supprimer"
                                @click="actionItem('delete', item)"
                            >
                                <v-icon dark>
                                    mdi-delete
                                </v-icon>
                            </v-btn>

                            <v-btn
                                :disabled="loading"
                                class="mx-2"
                                fab
                                dark
                                small
                                color="cyan"
                                title="Restaurer"
                                @click="actionItem('restore', item)"
                            >
                                <v-icon dark>
                                    mdi-file-restore
                                </v-icon>
                            </v-btn>
                        </div>

                        <div v-if="isType('image', item)" class="d-flex justify-content-center align-items-center" style="height: 100%">
                            <v-img
                                contain
                                width="100%"
                                height="auto"
                                max-height="100%"
                                :src="item.fullurl"
                            >   
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>

                            
                        </div>

                        <div v-if="isType('video', item)" class="d-flex justify-content-center align-items-center" style="height: 100%">
                            <video  width="100%" height="auto">
                                <source :src="item.fullurl">
                            </video>
                        </div>

                        <div v-if="!isType('video', item) && !isType('image', item)" class="other-files d-flex justify-content-center align-items-center">
                            <v-icon x-large>
                                mdi-file
                            </v-icon>
                        </div>

                        <v-expand-transition>
                            <div
                                v-if="hover"
                                class="d-flex flex-column transition-fast-in-fast-out darken-2 v-card--reveal hover-info"
                            >
                                <span>{{ item.filename }}</span>
                            </div>
                        </v-expand-transition>
                    </v-sheet>
                </v-hover>
                
            </v-col>
        </v-row>

        <div class="d-flex justify-content-between mt-8">
            <v-pagination
                v-if="lists.length > 0"
                v-model="pagination.current"
                :length="pagination.count"
                :total-visible="10"
            ></v-pagination>

            <v-select
                v-if="lists.length > 0"
                :items="optionsPage"
                value="value"
                item-text="label"
                solo
                v-model="pagination.totalItems"
                class="select-total"
            ></v-select>
        </div>
    </div>
</template>

<style>
    .select-total{
        width: 100px !important;
        flex: none;
    }

    .float-action{
        position: absolute;
        top: -18px;
        right: 0;
        z-index: 99;
    }

    .hover-info{
        height: 25%; 
        position: absolute; 
        bottom: 0;
        z-index: 10;
        width: 100%;
        background-color: white;
        color: black;
        padding: 5px 10px;
    }

    .other-files{
        width: 100%;
        height: 100%;
    }
</style>

<script>
    export default {
        name: 'storage-grill-archive',

        props: {
            actionItem: Function,
            checkItem: Function,
            selectall: String,
            visualize: Function,
            loading: Boolean,
            files: Array,
            isChecked: Function
        },

        data(){
            return {
                type: {
                    image: ['jpeg', 'jpg', 'png', 'gif', 'jiff', 'svg', 'bmp'],
                    video: ['mp4', 'mkv'],
                },

                current: [],
                pagination: {
                    current: 1,
                    count: 0,
                    totalItems: 10
                },

                lists: [],
                toShows: [],
                optionsPage: [
                    { label: '10', value: 10 },
                    { label: '15', value: 15 },
                    { label: 'All', value: 0 },
                ]
            }
        },

         watch: {
            files: {
                handler(){
                    this.pagination.current = 1
                    let tmp = JSON.parse(JSON.stringify(this.files));
                    this.lists= []
                    tmp.forEach(item => this.lists.push(item));
                    this.pagination.count = Math.ceil(this.lists.length / this.pagination.totalItems);

                    this.optionsPage[2].value = this.lists.length;

                    this.setToShowByCurrentPage()
                },
                immediate: true
            },

            'pagination.totalItems': {
                handler(val){
                    this.pagination.count = Math.ceil(this.lists.length / this.pagination.totalItems);
                    this.pagination.current = 1;

                    this.setToShowByCurrentPage()
                }
            },

            'pagination.current': {
                handler(){
                    this.setToShowByCurrentPage()
                }
            }
        },

        methods: {
            isType(type, file){
                switch(type){
                    case 'image' : return this.type.image.includes(this.getExtension(file.filename))
                        break;
                    case 'video': return this.type.video.includes(this.getExtension(file.filename))
                        break;
                }
            },

            getExtension(file){
                return file.split('.')[1]
            },

            setToShowByCurrentPage(){
                let max = (this.pagination.totalItems * this.pagination.current);
                let min = max - this.pagination.totalItems;
                this.toShows = this.lists.slice(min, max);
            },

            checkAll(event){
                this.$emit('checkall', event)
            }
        }
    }
</script>