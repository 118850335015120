<template>
  <div class="card">
    <div class="card-body">
      <h4>CATEGORIES</h4>
      <div class="tab-content">
          <form @submit.prevent="sendCategorie()" enctype="multipart/form-data">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                <label for="destintion">Categorie:</label>
                <select
                  name="country_id"
                  class="js-example-basic-single w-100 custom-input"
                >
                  <option value="vos categorie" placeholder="vos categories">
                    vos categories
                  </option>
                  <option
                    :value="listCatego.id"
                    v-for="(listCatego, lc) in listCategorie"
                    :key="lc"
                  >
                    {{ listCatego.title }}
                  </option>
                </select>
              </div>
              <hr />
              <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                <div class="form-group input-description">
                  <h4>Créer Nouveaux Categorie</h4>
                  <form @submit.prevent="sendCategorie()">
                    <label for="title_cate"></label>
                    <input
                    class="form-control"
                      type="text"
                      name="title"
                      id="title_cate"
                      placeholder="nauveaux categorie"
                      v-model="title"
                    />
                  </form>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-4 col-xs-4 col-sm-4">
                    <button
                      class="btn btn-primary"
                      type="submit"
                    >
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Axios from "axios";
import Toaster from 'v-toaster'
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'v-toaster/dist/v-toaster.css'

// optional set default imeout, the default is 10000 (10 seconds).
Vue.use(Toaster, { timeout: 1000 })
export default {
  data() {
    return {
      listCategorie: [],
      status: [],
      deleteCategorie: "",
      title: "",
    };
  },
  methods: {
    sendCategorie() {
      if (this.title == "") {
        this.$toaster.error("champ catégorie obligatoire");
      } else {
           this.$toaster.success("Categorie créée avec succès");
        Axios.post("sendCategorie", {
          title: this.title,
        }).then((response) => {
          this.cat = response;
        });
        this.title = "";
      }
    },
    getAllCategorie() {
      Axios.get("getAllCategorie").then((response) => {
        this.listCategorie = response.data.categories;
      });
    },

    deleteCat() {
      if (this.status == 0) {
        this.$toaster.error("Cochez pour supprimer");
      } else {
        this.$toaster.success("Catégorie supprimée avec succès");
        Axios.post("deletecategorie/", {
          deleteCategorie: this.status,
        }).then((response) => {
          this.deleteCategorie = response.succ
            ? response.succ
            : this.deleteCategorie;
        });
        (this.status =[])
      }
    },
  },
  mounted() {
    this.getAllCategorie();
  },
};
</script>