<template>
	<v-app>
		<v-data-table
			:search="search"
			:headers="headers"
			:items="questionnaire"
			:items-per-page="10"
			class="elevation-1"
			:loading="loading"
            :footer-props="options"
		>
			<template v-slot:item.voyageur="{ item }">
				{{
					item.voyageur ? item.voyageur.prenom + " " + item.voyageur.nom : ""
				}}
			</template>

			<template v-slot:item.continent="{ item }">
				{{ item.continent.continent_name }}
			</template>

			<template v-slot:item.actions="{ item }">
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-icon
							v-bind="attrs"
							v-on="on"
							small
							class="mr-2"
							@click="showItem(item)"
						>
							mdi-eye
						</v-icon>
					</template>
					<span>Voir</span>
				</v-tooltip>
			</template>
		</v-data-table>

		<ModalQuestionnaire
			:modal="modal"
			:questionnaire="show"
			@close="modal = false"
		></ModalQuestionnaire>
	</v-app>
</template>

<script>
import ModalQuestionnaire from "./modal.vue";

export default {
	props: {
		questionnaire: Array,
	},
	components: {
		ModalQuestionnaire,
	},
	data: () => ({
		baseUrl: window.baseUrl + "admin/",
		modal: false,
		show: null,
		loading: false,
		search: "",
		headers: [
			{ text: "Voyageurs", align: "center", sortable: false, value: "voyageur" },
			{ text: "Régions", align: "center", sortable: false, value: "continent" },
			{
				text: "Dates de création",
				align: "center",
				sortable: true,
				value: "created_at",
			},
			{ text: "Actions", value: "actions", align: "center", sortable: false },
		],

        options: {
            'items-per-page-text':'Eléments par page'
        }
	}),

	methods: {
		showItem(item) {
			this.modal = true;
			this.show = item;
		},

		close() {
			this.show = null;
			this.modal = false;
		},
	},
};
</script>

<style scoped>
.v-application--wrap {
	min-height: inherit !important;
}
</style>