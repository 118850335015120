<template>
    <v-dialog v-model="modal" max-width="1080" persistent>
        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                color="white"
                :size="50"
            ></v-progress-circular>
        </v-overlay>
        <v-card>
            <v-card-title
                class="text-h5 grey lighten-2 justify-content-between"
            >
                Nouvelle bannière

                <div class="d-flex justify-content-between">
                    <v-btn class="mr-2" color="primary" @click="close()">
                        Annuler
                    </v-btn>

                    <v-btn color="success" @click="save()"> Valider </v-btn>
                </div>
            </v-card-title>

            <v-card-text class="mt-2">
                <div class="row">
                    <div class="col-md-4">
                        <v-text-field
                            outlined
                            label="Titre"
                            v-model="model.title"
                            :error="error && error.title ? true : false"
                            :error-messages="
                                error && error.title ? error.title : null
                            "
                        ></v-text-field>
                    </div>

                    <div class="col-md-4">
                        <v-text-field
                            outlined
                            label="Alt"
                            v-model="model.alt"
                        ></v-text-field>
                    </div>

                    <div class="col-md-4">
                        <v-select
                            :options="positions"
                            label="label"
                            outlined
                            v-model="model.position"
                            :error="error && error.position ? true : false"
                            :error-messages="
                                error && error.position ? error.position : null
                            "
                        ></v-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <v-select
                            :options="articles"
                            label="title"
                            outlined
                            v-model="model.article"
                            return-object
                        ></v-select>
                    </div>

                    <div class="col-md-4">
                        <v-select
                            :options="circuits"
                            label="title_circuits"
                            outlined
                            v-model="model.circuit"
                            return-object
                            :error="error && error.circuit ? true : false"
                            :error-messages="
                                error && error.circuit ? error.circuit : null
                            "
                        ></v-select>
                    </div>

                    <div class="col-md-4">
                        <v-select
                            :options="types"
                            label="label"
                            outlined
                            v-model="model.type"
                            :error="error && error.type ? true : false"
                            :error-messages="
                                error && error.type ? error.type : null
                            "
                        ></v-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div id="banner">
                            <span class="ml-2" style="display: none">
                                <i class="fa fa-paperclip">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        @change="piece_jointe($event)"
                                        ref="banner"
                                /></i>
                            </span>

                            <v-btn block color="primary" @click="getFiles()">
                                Importer bannière
                            </v-btn>
                            <span
                                v-if="error && error.banner"
                                class="text-danger"
                            >
                                {{
                                    error && error.banner ? error.banner : null
                                }}
                            </span>
                            <div
                                class="img-banner w-100 rounded mt-2"
                                v-if="tmp_file"
                            >
                                <v-img
                                    :lazy-src="tmp_file"
                                    :src="tmp_file"
                                ></v-img>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Axios from "axios";

export default {
    name: "modal-banner",
    props: {
        banner: Object,
        update: Boolean,
        modal: Boolean,
        articles: Array,
        circuits: Array,
    },

    data() {
        return {
            baseUrl: window.origin + "/admin/",
            path: window.origin + "/public/image/banner/",
            model: {
                banner: null,
                title: null,
                alt: null,
                type: null,
                article: null,
                circuit: null,
                position: null,
            },

            tmp_file: null,

            types: [
                { label: "Reservation", value: "reservation" },
                { label: "Detail", value: "detail" },
            ],

            positions: [
                { label: "Laterale", value: "lateral" },
                { label: "Horizontal", value: "horizontal" },
            ],

            error: null,
            loading: false,
        };
    },

    watch: {
        banner: {
            handler(value) {
                if (value) {
                    console.log(value);
                    this.tmp_file = this.path + this.banner.banniere;
                    this.model.title = this.banner.title;
                    this.model.alt = this.banner.alt;
                    this.model.type = this.banner.type;
                    this.model.article = this.banner.article;
                    this.model.circuit = this.banner.circuit
                        ? this.banner.circuit
                        : this.banner.circuit_copy;
                    this.model.position = this.banner.position;
                }
            },
            immediate: true,
        },

        model: {
            handler(value) {
                let error = {
                    banner: null,
                    title: null,
                    type: null,
                    circuit: null,
                    position: null,
                };

                if (value.banner) {
                    if (this.error && this.error.banner)
                        this.error.banner = null;
                }

                if (value.title) {
                    if (this.error && this.error.title) this.error.title = null;
                }

                if (value.type) {
                    if (this.error && this.error.type) this.error.type = null;
                }

                if (value.circuit) {
                    if (this.error && this.error.circuit)
                        this.error.circuit = null;
                }

                if (value.position) {
                    if (this.error && this.error.position)
                        this.error.position = null;
                }
            },
            deep: true,
        },

        error: {
            handler(value) {
                if (Object.values(this.error).every((item) => item === null)) {
                    this.error = null;
                }
            },
            deep: true,
        },
    },

    methods: {
        close() {
            this.reset();
            this.$emit("close");
        },

        reset() {
            this.model = {
                banner: null,
                title: null,
                alt: null,
                type: null,
                article: null,
                circuit: null,
            };

            (this.error = null), (this.tmp_file = null);
        },

        piece_jointe: function (event) {
            var input = event.target;

            if (input.files && input.files[0]) {
                this.model.banner = input.files[0];
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.tmp_file = e.target.result;
                };

                reader.readAsDataURL(input.files[0]);
            }
        },

        getFiles() {
            this.$refs.banner.click();
        },

        valide() {
            var is_error = false;
            let error = {
                banner: null,
                title: null,
                type: null,
                circuit: null,
                position: null,
            };

            if (!this.model.banner && !this.update) {
                is_error = true;
                error.banner = "Bannière réquise";
            }

            if (!this.model.title) {
                is_error = true;
                error.title = "Titre réquis";
            }

            if (!this.model.type) {
                is_error = true;
                error.type = "Type réquis";
            }

            if (!this.model.circuit) {
                is_error = true;
                error.circuit = "Circuit réquis";
            }

            if (!this.model.position) {
                is_error = true;
                error.position = "Position réquise";
            }

            if (is_error) {
                this.error = error;
            }

            return is_error;
        },

        filter_copy(circuit, form_data) {
            form_data.append(
                "circuit",
                circuit.url ? circuit.url.value : circuit.id
            );
            if (circuit.circuit_id) {
                form_data.append("circuit_type", "copy");
            } else {
                form_data.append("circuit_type", "original");
            }
        },

        save() {
            let is_invalid = this.valide();

            if (!is_invalid) {
                this.loading = true;
                var form_data = new FormData();
                if (!this.update) {
                    form_data.append("banner", this.tmp_file);
                } else {
                    if (this.model.banner) {
                        if (this.tmp_file.includes(this.path)) {
                            form_data.append("banner", null);
                        } else {
                            form_data.append("banner", this.tmp_file);
                        }
                    }
                }
                form_data.append("title", this.model.title);
                form_data.append("type", this.model.type.value);
                form_data.append("position", this.model.position.value);

                this.filter_copy(this.model.circuit, form_data);

                if (this.model.alt) form_data.append("alt", this.model.alt);
                if (this.model.article)
                    form_data.append(
                        "article",
                        this.model.article.url
                            ? this.model.article.url.value
                            : this.model.article.id
                    );

                if (!this.update) {
                    this.create_banner(form_data);
                } else {
                    this.update_banner(form_data, this.banner.id);
                }
            }
        },

        create_banner(data) {
            Axios.post(this.baseUrl + "banniere", data).then((response) => {
                this.loading = false;
                if (response.data.success) {
                    this.$toaster.success(response.data.success);
                    this.close();
                    this.$emit("refresh-list");
                    window.location.href;
                }

                if (response.data.error) {
                    this.$toaster.error(
                        "Une erreur est survenue, veuillez réessayer"
                    );
                    console.log("error banniere", response.data.error);
                }
            });
        },

        update_banner(data, id) {
            data.append("_method", "PUT");
            Axios.post(this.baseUrl + "banniere/" + id, data).then(
                (response) => {
                    this.loading = false;
                    if (response.data.success) {
                        this.$toaster.success(response.data.success);
                        this.close();
                        this.$emit("refresh-list");
                        window.location.href;
                    }

                    if (response.data.error) {
                        this.$toaster.error(
                            "Une erreur est survenue, veuillez réessayer"
                        );
                        console.log("error banniere", response.data.error);
                    }
                }
            );
        },
    },
};
</script>

<style scoped></style>
