<template>
    <div>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="6">
                        <div class="d-flex">
                            <v-btn
                                :disabled="loading"
                                v-if="fileselected.length > 0"
                                class="mx-2"
                                dark
                                small
                                color="error"
                                title="Supprimer tous les fichiers séléctionnés"
                                @click="actionItem('delete')"
                            >
                                Supprimer tous les fichiers séléctionnés
                                <v-icon dark>
                                    mdi-delete
                                </v-icon>
                            </v-btn>

                            <v-btn
                                :disabled="loading"
                                v-if="fileselected.length > 0"
                                class="mx-2"
                                dark
                                small
                                color="cyan"
                                title="Restaurer tous les fichiers séléctionnés"
                                @click="actionItem('restore')"
                            >
                                Restaurer tous les fichiers séléctionnés
                                <v-icon dark>
                                    mdi-file-restore
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-col>

                    <v-col cols="6">
                        <div class="d-flex justify-content-end">
                            <v-btn
                                :disabled="loading"
                                class="mx-2"
                                fab
                                dark
                                small
                                color="primary"
                                title="Rafraichir"
                                @click="retrieve()"
                            >
                                <v-icon dark>
                                    mdi-refresh
                                </v-icon>
                            </v-btn>

                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                :color="mode ? 'purple' : 'teal'"
                                :title="mode ? 'Liste' : 'Grille'"
                                @click="mode = !mode"
                            >
                                <v-icon v-if="!mode" dark>
                                    mdi-apps
                                </v-icon>

                                <v-icon v-if="mode" dark>
                                    mdi-view-list
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <storage-list-archive
                    v-if="mode"
                    :actionItem="actionItem"
                    :checkItem="checkItem"
                    :selectall="selectall"
                    :visualize="visualize"
                    :loading="loading"
                    :files="files"
                    @checkall="checkAll"
                    :isChecked="isChecked"
                ></storage-list-archive>

                <storage-grill-archive
                    v-if="!mode"
                    :actionItem="actionItem"
                    :checkItem="checkItem"
                    :selectall="selectall"
                    :visualize="visualize"
                    :loading="loading"
                    :files="files"
                    @checkall="checkAll"
                    :isChecked="isChecked"
                ></storage-grill-archive>
            </v-card-text>
        </v-card>

        <storage-visualize-file
            @close="stopVisualize"
            :files="show"
        ></storage-visualize-file>
    </div>
</template>

<style >
    .v-label{
        margin-bottom: 0;
    }
</style>

<script>
    import Axios from 'axios';

    export default {
        name: 'storage-file-archive',

        props: {
            headers: Object,
            selected: Boolean,
            is_loading: Boolean,
            model: String,
            labelName: String,
            type: String, // if ftp or ftpvideo
            headerModal: String
        },

        data(){
            return {
                baseUrl: window.baseUrl + 'api/admin/storage/' + this.model + '/archive',
                loading: false,
                files: [],
                selectall: null,
                fileselected: [],
                fromOther: false,
                show: null,
                mode: true
            }
        },

        watch: {
            is_loading(val){
                if(val){
                    this.retrieve()
                }
            },

            // if tab selected
            selected: {
                handler(val){
                    if(val){
                        this.retrieve()
                    }
                },
                immediate: true
            },

            // if checkbox select only inused file checked
            selectall: {
                handler(val){
                    this.selectAllFiles(val)
                },
                immediate: true
            },

            // if fileselected changed value
            fileselected: {
                handler(val){
                    if(!val){
                        this.fileselected = []
                    }
                },
                immediate: true
            }
        },

        methods: {
            retrieve(){
                this.loading = true;
                try{
                    Axios.get(this.baseUrl, { headers: this.headers }).then(response => {
                        this.files = response.data
                        this.selectAllFiles(this.selectall)
                    }).catch(e => {
                        this.$toaster.error("Une erreur est survenue, veuillez réessayer \n " + error.message);
                        console.log('error', e)
                    }).finally(() => {
                        this.loading = false;
                        this.$emit('termined');
                    })
                }catch(error){
                    this.$toaster.error("Une erreur est survenue, veuillez réessayer \n " + error.message);
                    console.log('error', e)
                }
            },

            selectAllFiles(val){
                if(!this.fromOther){
                    if(!val){
                        this.fileselected = []
                    }else{
                        this.files.forEach(item => {
                            if(!item.target){
                                if(!this.fileselected.includes(item)){
                                    this.fileselected.push(item)
                                }
                            }
                        })
                    }
                }

                this.fromOther = false
            },

            actionItem(action, item = null){
                this.$emit('isaction', {
                    model: this.model,
                    type: this.type,
                    storage: item ? [item] : this.fileselected,
                    header: this.headerModal
                }, action)
            },

            checkItem(event, item){
                if(event){
                    if(!this.fileselected.includes(item)){
                        this.fileselected.push(item)
                    }
                }else{
                    this.fromOther = true;
                    this.selectall = null;
                    let i = this.fileselected.findIndex(el => 
                        el.filename == item.filename 
                        && el.path == item.path 
                        && el.realpath == item.realpath 
                        && el.target == item.target 
                    )

                    if(i > -1){
                        this.fileselected.splice(i, 1)
                    }
                }
            },

            visualize(item, other){
                this.show = item
            },

            stopVisualize(){
                this.show = null
            },

            checkAll(event){
                this.selectall = event
            },

            isChecked(item){
                return this.fileselected.find(el => 
                    el.filename == item.filename
                    && el.realpath == item.realpath
                    && el.fullurl == item.fullurl
                ) ? true : false
            },
        }
    }
</script>
