<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div style="background-color: #fff" class="d-flex">
                    <v-tabs v-model="model" centered slider-color="yellow">
                        <v-tab
                            v-for="(item, i) in items"
                            :key="i"
                            :href="`#tab-${i}`"
                        >
                            {{ item }}
                        </v-tab>
                    </v-tabs>
                    <input
                        v-model="searchMessage"
                        v-on:keyup="search()"
                        v-on:keydown="clearTyping()"
                        style="
                            background-color: #fff;
                            padding: 20px 0 0 0;
                            border-bottom: 1px #000 solid;
                            margin-right: 20px;
                            font-size: 14px;
                        "
                        type="text"
                        placeholder="rechercher une message"
                    />
                </div>

                <v-tabs-items v-model="model">
                    <v-tab-item
                        v-for="(item, i) in items"
                        :key="i"
                        :value="`tab-${i}`"
                    >
                        <v-card flat>
                            <link
                                href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
                                rel="stylesheet"
                            />
                            <div class="container bootdey">
                                <div class="email-app mb-4">
                                    <nav>
                                        <a
                                            href="#"
                                            class="btn btn-danger btn-block"
                                            @click.prevent="sendNewEmail()"
                                        >
                                            Nouveau Email
                                        </a>
                                        <ul class="nav">
                                            <li
                                                :class="
                                                    tab_menue === 'Inbox'
                                                        ? 'nav-item active'
                                                        : 'nav-item'
                                                "
                                            >
                                                <a
                                                    class="nav-link"
                                                    href="#"
                                                    @click.prevent="
                                                        getDiscussionForClientOrReceptive(
                                                            i,
                                                            'Inbox',
                                                            1
                                                        )
                                                    "
                                                    ><i class="fa fa-inbox"></i>
                                                    Inbox
                                                    <span
                                                        class="badge badge-danger"
                                                        >{{
                                                            i == 0
                                                                ? not_read_receptive
                                                                : not_read_client
                                                        }}</span
                                                    >
                                                </a>
                                            </li>
                                            <!-- <li class="nav-item">
                                                <a class="nav-link" href="#"
                                                    ><i class="fa fa-star"></i> Stared</a
                                                >
                                            </li> -->
                                            <li
                                                class="nav-item"
                                                :class="
                                                    tab_menue === 'Sent'
                                                        ? 'nav-item active'
                                                        : 'nav-item'
                                                "
                                            >
                                                <a
                                                    class="nav-link"
                                                    href="#"
                                                    @click.prevent="
                                                        getDiscussionForClientOrReceptive(
                                                            i,
                                                            'Sent',
                                                            1
                                                        )
                                                    "
                                                    ><i
                                                        class="fa fa-rocket"
                                                    ></i>
                                                    Sent</a
                                                >
                                            </li>
                                            <li
                                                @click.prevent="
                                                    tab_menue = 'Trash'
                                                "
                                                class="nav-item"
                                                :class="
                                                    tab_menue === 'Trash'
                                                        ? 'nav-item active'
                                                        : 'nav-item'
                                                "
                                            >
                                                <a
                                                    class="nav-link"
                                                    href="#"
                                                    @click.prevent="
                                                        tab_menue = 'Trash'
                                                    "
                                                    ><i
                                                        class="fa fa-trash-o"
                                                    ></i>
                                                    Trash</a
                                                >
                                            </li>
                                        </ul>
                                    </nav>
                                    <main class="inbox">
                                        <div class="toolbar">
                                            <div class="btn-group"></div>
                                            <div class="btn-group"></div>
                                            <div class="btn-group"></div>
                                            <div class="btn-group float-right">
                                                <pagination
                                                    :items="paginationData"
                                                    @changePage="onChangePage"
                                                    @setPage="getPage"
                                                ></pagination>
                                                <!-- <button
													type="button"
													class="btn btn-light"
													@click="withPagination('left')"
													:disabled="leftButton"
												>
													<span class="fa fa-chevron-left"></span>
												</button>
												<button
													type="button"
													class="btn btn-light"
													:disabled="rigthButton"
													@click="withPagination('right')"
												>
													<span class="fa fa-chevron-right"></span>
												</button> -->
                                            </div>
                                        </div>
                                        <ul
                                            class="messages"
                                            v-if="
                                                tab_menue === 'Inbox' &&
                                                parseInt(i, 10) == 1
                                            "
                                        >
                                            <li
                                                :class="
                                                    client.status == 0
                                                        ? 'message unread'
                                                        : 'message'
                                                "
                                                v-for="(
                                                    client, cl
                                                ) in pageofItem"
                                                @click="
                                                    showMessage(
                                                        client,
                                                        'client'
                                                    )
                                                "
                                                :key="cl"
                                            >
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#exampleModal"
                                                >
                                                    <div class="actions">
                                                        <span class="action"
                                                            ><i
                                                                class="fa fa-square-o"
                                                            ></i
                                                        ></span>
                                                        <span class="action"
                                                            ><i
                                                                class="fa fa-star-o"
                                                            ></i
                                                        ></span>
                                                    </div>
                                                    <div class="header">
                                                        <span class="from"
                                                            >{{
                                                                client.prenom
                                                            }}
                                                            {{
                                                                client.nom
                                                            }}</span
                                                        >
                                                        <span class="date">
                                                            <span
                                                                class="fa fa-paper-clip"
                                                            ></span>
                                                            {{
                                                                format(
                                                                    client.created_at
                                                                )
                                                            }}</span
                                                        >
                                                    </div>
                                                    <div class="title">
                                                        {{ client.objet }}
                                                    </div>
                                                    <div class="description">
                                                        {{ client.message }}
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                        <div
                                            v-if="tab_menue == 'Show'"
                                            class="messages"
                                        >
                                            <li class="message">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#exampleModal"
                                                >
                                                    <div class="actions">
                                                        <span class="action"
                                                            ><i
                                                                class="fa fa-square-o"
                                                            ></i
                                                        ></span>
                                                        <span class="action"
                                                            ><i
                                                                class="fa fa-star-o"
                                                            ></i
                                                        ></span>
                                                    </div>
                                                    <div class="header">
                                                        <span
                                                            class="from"
                                                            v-if="
                                                                typeUser ==
                                                                'client'
                                                            "
                                                        >
                                                            {{
                                                                tempMessages.prenom
                                                            }}
                                                            {{
                                                                tempMessages.nom
                                                            }}
                                                        </span>
                                                        <span
                                                            class="from"
                                                            v-else
                                                        >
                                                            {{
                                                                tempMessages.firstname
                                                            }}
                                                            {{
                                                                tempMessages.lastname
                                                            }}
                                                        </span>

                                                        <!-- <span class="from"
															>{{ typeUser == 'client' ?  tempMessages.client.prenom : tempMessages.customers.firstname }}
															{{ typeUser == 'receptive' ?  tempMessages.client.nom  : tempMessages.customers.lastname}}</span
														> -->
                                                        <span class="date">
                                                            <span
                                                                class="fa fa-paper-clip"
                                                            ></span>
                                                            {{
                                                                format(
                                                                    tempMessages.created_at
                                                                )
                                                            }}</span
                                                        >
                                                    </div>
                                                    <div class="title">
                                                        {{ tempMessages.objet }}
                                                    </div>
                                                    <div class="description">
                                                        {{
                                                            tempMessages.message
                                                        }}
                                                    </div>
                                                    <div
                                                        style="
                                                            display: flex;
                                                            justify-content: flex-end;
                                                        "
                                                        class="repondre"
                                                    >
                                                        <span
                                                            class="date"
                                                            @click.prevent="
                                                                getIdType(
                                                                    tempMessages
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="fa fa-reply"
                                                                aria-hidden="true"
                                                            ></i>
                                                            Repondre
                                                        </span>
                                                    </div>

                                                    <div
                                                        style="
                                                            display: flex;
                                                            justify-content: flex-end;
                                                        "
                                                        class="repondre mt-3"
                                                    >
                                                        <span
                                                            class="date"
                                                            @click.prevent="
                                                                getIdTypeTransfer(
                                                                    tempMessages
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="fa fa-share-alt"
                                                                aria-hidden="true"
                                                            ></i>
                                                            Transferer
                                                        </span>
                                                    </div>

                                                    <div
                                                        style="
                                                            display: flex;
                                                            justify-content: flex-end;
                                                        "
                                                        class="repondre mt-3"
                                                        v-if="
                                                            tempMessages
                                                                .circuits
                                                                .length > 0
                                                        "
                                                    >
                                                        Circuit :
                                                        {{
                                                            tempMessages
                                                                .circuits[0]
                                                                .title_circuits
                                                        }}
                                                    </div>
                                                    <div
                                                        style="
                                                            display: flex;
                                                            justify-content: flex-end;
                                                        "
                                                        class="repondre mt-3"
                                                        v-else-if="
                                                            tempMessages
                                                                .circuit_copies
                                                                .length > 0
                                                        "
                                                    >
                                                        Circuit :
                                                        {{
                                                            tempMessages
                                                                .circuit_copies[0]
                                                                .title_circuits
                                                        }}
                                                    </div>
                                                    <div v-else></div>

                                                    <div
                                                        class="objet"
                                                        v-for="(
                                                            f, index
                                                        ) in tempMessages.discussion_pieces_joint"
                                                        :key="index"
                                                    >
                                                        <a
                                                            :href="
                                                                baseUrl +
                                                                imgPathPiece +
                                                                f.file
                                                            "
                                                            alt="mc"
                                                            target="_black"
                                                            title="cliquer pour voir"
                                                        >
                                                            <embed
                                                                v-if="
                                                                    getExtension(
                                                                        f.file
                                                                    ) == 'image'
                                                                "
                                                                :src="
                                                                    baseUrl +
                                                                    imgPathPiece +
                                                                    f.file
                                                                "
                                                                accept="image/*"
                                                                width="150px"
                                                                height="150px"
                                                            />

                                                            <div
                                                                class="icon-discu"
                                                                v-else
                                                            >
                                                                <span
                                                                    class="mdi"
                                                                    :class="
                                                                        getExtension(
                                                                            f.file
                                                                        ) ==
                                                                        'video'
                                                                            ? 'mdi-file-video'
                                                                            : 'mdi-file'
                                                                    "
                                                                    :title="
                                                                        getExtension(
                                                                            f.file
                                                                        ) ==
                                                                        'video'
                                                                            ? 'Vidéo'
                                                                            : 'Document'
                                                                    "
                                                                    width="150px"
                                                                    height="150px"
                                                                ></span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </a>
                                            </li>
                                        </div>
                                        <ul
                                            class="messages"
                                            v-if="
                                                tab_menue === 'Inbox' &&
                                                parseInt(i, 10) == 0
                                            "
                                        >
                                            <li
                                                :class="
                                                    receptive.status == 0
                                                        ? 'message unread'
                                                        : 'message'
                                                "
                                                v-for="(
                                                    receptive, cl
                                                ) in pageofItem"
                                                :key="cl"
                                                @click.prevent="
                                                    showMessage(
                                                        receptive,
                                                        'receptive'
                                                    )
                                                "
                                            >
                                                <a href="#">
                                                    <div class="actions">
                                                        <span class="action"
                                                            ><i
                                                                class="fa fa-square-o"
                                                            ></i
                                                        ></span>
                                                        <span class="action"
                                                            ><i
                                                                class="fa fa-star-o"
                                                            ></i
                                                        ></span>
                                                    </div>
                                                    <div class="header">
                                                        <span class="from"
                                                            >{{
                                                                receptive.firstname
                                                            }}
                                                            {{
                                                                receptive.lastname
                                                            }}</span
                                                        >
                                                        <span class="date">
                                                            <span
                                                                class="fa fa-paper-clip"
                                                            ></span>
                                                            {{
                                                                format(
                                                                    receptive.created_at
                                                                )
                                                            }}</span
                                                        >
                                                    </div>
                                                    <div class="title">
                                                        {{ receptive.objet }}
                                                    </div>
                                                    <div class="description">
                                                        {{ receptive.message }}
                                                    </div>
                                                    <div
                                                        class="description"
                                                        v-if="
                                                            receptive.circuits
                                                                .length > 0
                                                        "
                                                    >
                                                        Circuit :
                                                        {{
                                                            receptive
                                                                .circuits[0]
                                                                .title_circuits
                                                        }}
                                                    </div>
                                                    <div
                                                        class="description"
                                                        v-else-if="
                                                            receptive
                                                                .circuit_copies
                                                                .length > 0
                                                        "
                                                    >
                                                        Circuit :
                                                        {{
                                                            receptive
                                                                .circuit_copies[0]
                                                                .title_circuits
                                                        }}
                                                    </div>
                                                    <div v-else></div>
                                                </a>
                                            </li>
                                        </ul>
                                        <ul
                                            class="messages"
                                            v-if="tab_menue === 'Trash'"
                                        >
                                            <li
                                                class="message unread"
                                                v-for="(
                                                    receptive, cl
                                                ) in trashs"
                                                :key="cl"
                                            >
                                                <a href="#">
                                                    <div class="actions">
                                                        <span class="action"
                                                            ><i
                                                                class="fa fa-square-o"
                                                            ></i
                                                        ></span>
                                                        <span class="action"
                                                            ><i
                                                                class="fa fa-star-o"
                                                            ></i
                                                        ></span>
                                                    </div>
                                                    <div class="header">
                                                        <span class="from">{{
                                                            current_user.name
                                                        }}</span>
                                                        <span class="date">
                                                            <span
                                                                class="fa fa-paper-clip"
                                                            ></span>
                                                            {{
                                                                format(
                                                                    receptive.created_at
                                                                )
                                                            }}</span
                                                        >
                                                    </div>
                                                    <div class="title">
                                                        {{ receptive.objet }}
                                                    </div>
                                                    <div class="description">
                                                        {{ receptive.message }}
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                        <ul
                                            class="messages"
                                            v-if="tab_menue === 'Sent'"
                                        >
                                            <li
                                                class="message unread"
                                                v-for="(receptive, cl) in sents"
                                                :key="cl"
                                            >
                                                <a href="#">
                                                    <div class="actions">
                                                        <span class="action"
                                                            ><i
                                                                class="fa fa-square-o"
                                                            ></i
                                                        ></span>
                                                        <span class="action"
                                                            ><i
                                                                class="fa fa-star-o"
                                                            ></i
                                                        ></span>
                                                    </div>
                                                    <div class="header">
                                                        <span class="from">{{
                                                            current_user.name
                                                        }}</span>
                                                        <span class="date">
                                                            <span
                                                                class="fa fa-paper-clip"
                                                            ></span>
                                                            {{
                                                                format(
                                                                    receptive.created_at
                                                                )
                                                            }}</span
                                                        >
                                                    </div>
                                                    <div class="title">
                                                        {{ receptive.objet }}
                                                    </div>
                                                    <div class="description">
                                                        {{ receptive.message }}
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                        <div
                                            class="dic_send"
                                            v-if="tab_menue === 'Email'"
                                        >
                                            <v-select
                                                v-model="idReceptive"
                                                v-if="parseInt(i, 10) == 0"
                                                class="my-3"
                                                :options="toReceptive"
                                                filled
                                                label="firstname"
                                                placeholder="A"
                                            >
                                                <template
                                                    #option="{ firstname, email }"
                                                >
                                                    <p style="margin: 0">
                                                        {{ firstname }}
                                                    </p>
                                                    <em
                                                        > ({{ email }})
                                                    </em
                                                    >
                                                </template>
                                            </v-select>
                                            <v-select
                                                v-model="idClient"
                                                v-if="parseInt(i, 10) == 1"
                                                class="my-3"
                                                :options="toClients"
                                                filled
                                                label="nom"
                                                placeholder="A"
                                            >
											 <template
                                                    #option="{ nom, email }"
                                                >
                                                    <p style="margin: 0">
                                                        {{ nom }}
                                                    </p>
                                                    <em
                                                        > ({{ email }})
                                                    </em
                                                    >
                                                </template>
											</v-select>
                                            <input
                                                type="text"
                                                placeholder="Objet"
                                                v-model="discussion.objet"
                                            />
                                            <div class="form-bottom">
                                                <textarea
                                                    rows="4"
                                                    cols="60"
                                                    type="text"
                                                    v-model="discussion.message"
                                                    @keyup.enter="
                                                        sendDiscussion(i)
                                                    "
                                                    class="write_msg"
                                                    placeholder="Votre message"
                                                    autocomplete="off"
                                                    required
                                                />
                                            </div>
                                            <div class="boutton-env">
                                                <button
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Cliquez ici pour envoyer votre message"
                                                    class="msge_sende_btn envoyer ronf"
                                                    type="submit"
                                                    id="send"
                                                    @click="sendDiscussion(i)"
                                                >
                                                    <i
                                                        class="fa fa-paper-plane-o send"
                                                        aria-hidden="true"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="add_piece_joint ronf"
                                                >
                                                    <button
                                                        title="Ajouter ici votre pièce jointe"
                                                        type="button"
                                                        class="btn ajout_pieces p-0"
                                                    >
                                                        <span
                                                            ><i
                                                                class="fa fa-paperclip"
                                                            ></i
                                                        ></span>
                                                    </button>
                                                    <input
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Ajouter ici votre pièce jointe"
                                                        type="file"
                                                        accept="application/*,image/*"
                                                        multiple
                                                        class="form-control piece_jointe p-0"
                                                        @change="
                                                            piece_jointe($event)
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
        <!-- <h3 class="text-center">Messages</h3>
    <div class="messaging">
      <div class="inbox_msg">
        <div class="inbox_people">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="conversationLibre-tab"
                data-toggle="tab"
                href="#conversationLibre"
                role="tab"
                aria-controls="conversationLibre"
                aria-selected="true"
                >Libre
                <span class="badge badge-custome">
                  {{ listDiscussionLibreNonLue }}
                </span></a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="convesationNow-tab"
                data-toggle="tab"
                href="#convesationNow"
                role="tab"
                aria-controls="convesationNow"
                aria-selected="false"
                >En cours
                <span class="badge badge-custome">
                  {{ listDiscussionEnCourNonLue }}
                </span></a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="conversationLibre"
              role="tabpanel"
              aria-labelledby="conversationLibre-tab"
            >
              <div class="inbox_chat">
                <div
                  class="chat_list active_chat"
                  v-for="(listDiscussion, ld) in listDiscussionL"
                  :key="ld"
                  v-bind:class="{
                    non_lue:
                      listDiscussion.discussion.length > 0
                        ? listDiscussion.discussion[0].user_id == null
                        : '',
                  }"
                >
                  <div
                    @click="getIdDiscussion(listDiscussion)"
                    class="chat_people"
                  >
                    <div class="chat_ib" v-if="listDiscussion.discussion.length > 0">
                      <h4>
                        {{
                          listDiscussion.client != null
                            ? listDiscussion.client
                              ? listDiscussion.client.nom +
                                " " +
                                listDiscussion.client.prenom
                              : ""
                            : listDiscussion.customers
                            ? listDiscussion.customers.firstname +
                              " " +
                              listDiscussion.customers.lastname
                            : ""
                        }}
                      </h4>
                      <h5>
                        {{
                          listDiscussion.discussion.length > 0
                            ? listDiscussion.discussion[0].message
                            : ""
                        }}
                        <br /><br />
                        &nbsp;&nbsp;<span class="chat_date">
                          {{
                                  new Date(listDiscussion.discussion[0].created_at)|dateFormat("DD MMMM YYYY h:mm")
                          }}</span
                        >
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="convesationNow"
              role="tabpanel"
              aria-labelledby="convesationNow-tab"
            >
              <div class="inbox_chat">
                <div
                  class="chat_list active_chat"
                  v-for="(listDiscussion, ld) in listDiscussions"
                  :key="ld"
                  v-bind:class="{
                    non_lue:
                      listDiscussion.discussion.length > 0
                        ? listDiscussion.discussion[0].status == 0 &&
                          listDiscussion.discussion[0].sender_id ==
                            listDiscussion.client_id
                        : '',
                  }"
                >
                  <div
                    @click="getIdDiscussion(listDiscussion)"
                    class="chat_people"
                  >
                    <div class="chat_ib" v-if="listDiscussion.discussion.length > 0">
                      <h4>
                        {{
                          listDiscussion.client != null
                            ? listDiscussion.client
                              ? listDiscussion.client.nom +
                                " " +
                                listDiscussion.client.prenom
                              : ""
                            : listDiscussion.customers
                            ? listDiscussion.customers.firstname +
                              " " +
                              listDiscussion.customers.lastname
                            : ""
                        }}
                      </h4>
                      <hr />
                      <h5>
                        {{
                          listDiscussion.discussion.length > 0
                            ? listDiscussion.discussion[0].message.substr(0, 30)
                            : ""
                        }}
                        <br /><br />
                        &nbsp;&nbsp;<span class="chat_date">
                           {{
                                  new Date(listDiscussion.discussion[0].created_at)|dateFormat("DD MMMM YYYY h:mm")
                          }}</span
                        >
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mesgs" v-if="discussionprivee.length != 0">
          <div class="msg_history">
            <div class="card-body height3">
              <div v-for="(discussionpri, dp) in discussionprivee" :key="dp">
                <ul class="navbar-nav">
                  <li
                    navbar-item
                    v-bind:class="{
                      in: discussionpri.sender_id != admin_id,
                      out: discussionpri.sender_id == admin_id,
                    }"
                  >
                    <div class="">
                      <div class="img-disc">
                        <h5>{{ discussionpri.message }}</h5>
                        <div class="row">
                          <div
                            class="col-md-3"
                            v-for="f in discussionpri.discussion_pieces_joint"
                            :key="f.id"
                          >
                            <a
                              :href="baseUrl + imgPathPiece + f.file"
                              alt="mc"
                              target="_black"
                              title="cliquer pour voir"
                            >
                              <embed
                                v-if="getExtension(f.file) == 'image'"
                                :src="baseUrl + imgPathPiece + f.file"
                                accept="image/*"
                                width="150px"
                                height="150px"
                              />

                              <div class="icon-discu" v-else>
                                <span
                                  class="mdi"
                                  :class="
                                    getExtension(f.file) == 'video'
                                      ? 'mdi-file-video'
                                      : 'mdi-file'
                                  "
                                  :title="
                                    getExtension(f.file) == 'video'
                                      ? 'Vidéo'
                                      : 'Document'
                                  "
                                  width="150px"
                                  height="150px"
                                ></span>
                              </div>
                            </a>
                          </div>
                        </div>
                        <br /><br />
                        <p>
                          {{new Date(discussionpri.created_at)|dateFormat("DD MMMM YYYY h:mm")}}
                        </p>
                      </div>
                    </div>
                  </li>
                  <hr />
                </ul>
              </div>
            </div>
          </div>

          <div id="success">
            <form>
              <div class="row content_images_pieces_joint">
                <div
                  class="piece_jointe_container"
                  v-for="(f, index) in discussion.discussion_pieces_joint"
                  :key="index"
                >
                  <div class="btn-piece">
                    <button
                      v-if="discussion.discussion_pieces_joint.length > 1"
                      @click="supprimer_piece_j(index)"
                      type="button"
                      class="btn del_pieces"
                    >
                      <span><i class="fa fa-trash"></i></span>
                    </button>
                    <i class="material-icons"></i>
                    <embed
                      :src="f.file"
                      page="2"
                    />
                  </div>
                </div>
              </div>
            </form>
            <div class="dic_send">
                <input
                    type="text"
                    placeholder="Objet"
                    v-model="discussion.objet"
                />
                <div class="form-bottom">
                    <textarea
                        type="text"
                        v-model="discussion.message"
                        @keyup.enter="sendDiscussion"
                        class="write_msg"
                        placeholder="Votre message"
                        autocomplete="off"
                        required
                    />
                </div>
                <div class="boutton-env">
                    <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Cliquez ici pour envoyer votre message"
                        class="msge_sende_btn envoyer ronf"
                        type="submit"
                        id="send"
                        @click="sendDiscussion"
                    >
                        <i class="fa fa-paper-plane-o send" aria-hidden="true"></i>
                    </button>
                    <div class="add_piece_joint ronf">
                    <button
                        title="Ajouter ici votre pièce jointe"
                        type="button"
                        class="btn ajout_pieces p-0"
                    >
                        <span><i class="fa fa-paperclip"></i></span>
                    </button>
                    <input
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Ajouter ici votre pièce jointe"
                        type="file"
                        accept="application/*,image/*"
                        multiple
                        class="form-control piece_jointe p-0"
                        @change="piece_jointe($event)"
                    />
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    </div>
</template>

<script>
import Vue from "vue";
import Axios from "axios";
import Toaster from "v-toaster";
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import "v-toaster/dist/v-toaster.css";
import moment from "moment";
// optional set default imeout, the default is 10000 (10 seconds).
Vue.use(Toaster, { timeout: 1000 });
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
import pagination from "./pagination";

export default {
    components: {
        pagination,
    },
    data() {
        return {
            message_corps: "",
            listDiscussions: [],
            searchMessage: "",
            admin_id: [],
            dialog: false,
            tempMessages: null,
            //   baseUrl: "https://admin.noah-voyages.com/",
            //   baseUrl: "http://localhost/noah-voyage-api/",
            baseUrl: window.baseUrl,
            listDiscussionL: [],
            discussionprivee: [],
            listDiscussionLibreNonLue: [],
            listDiscussionEnCourNonLue: [],
            receptive_discussions: [],
            client_discussions: [],
            imgPathPiece: "public/storage/back/img/discussions/",
            listPieceJoint: [],
            current_user: [],
            clientid: "",
            Clientnom: null,
            users: null,
            fichier: [],
            sents: [],
            trashs: [],
            clients_messages: [],
            receptive_messages: [],
            discussion: {
                message: "",
                objet: "",
                discussion_pieces_joint: [],
            },
            items: ["Receptive", "Client"],
            model: "tab-0",
            tab_menue: "",
            toClients: [],
            toReceptive: [],
            idReceptive: "",
            idClient: "",
            typeUser: "",
            chat_discussion_id: "",
            not_read_receptive: 0,
            not_read_client: 0,
            typing: null,
            // options: {
            // 	texts: {
            // 		count: "",
            // 		first: "",
            // 		last: "",
            // 	},
            // 	theme: "bootstrap4",
            // 	template: paginate,
            // },
            infinite: {
                current: 1,
                total: 1,
                count: 1,
            },
            leftButton: false,
            rigthButton: false,
            idSend: "",
            pageofItem: [],
            paginationData: [],
        };
    },

    methods: {
        format: function (value) {
            return moment(value).format("DD MMMM YYYY  hh:mm");
        },

        search() {
            this.typing = setTimeout(
                function () {
                    let index = this.model == "tab-0" ? 0 : 1;
                    this.getDiscussionForClientOrReceptive(
                        index,
                        this.tab_menue,
                        1
                    );
                }.bind(this),
                500
            );
        },

        onChangePage(pageOfItems) {
            this.pageofItem = pageOfItems;
        },
        getPage(pager) {
            this.infinite.current = pager.currentPage;
        },

        clearTyping() {
            clearTimeout(this.typing);
        },

        piece_jointe: function (event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.discussion.discussion_pieces_joint.push({
                        file: e.target.result,
                    });
                };
                reader.readAsDataURL(input.files[0]);
            }
        },

        getIdType(idType) {
            if (idType.nom) {
                this.idClient = idType;
            } else {
                this.idReceptive = idType;
            }
            this.tab_menue = "Email";
            this.discussion.objet = "Reponse à :" + idType.objet;
            this.chat_discussion_id = idType.chat_discussion_id;
        },

        getIdTypeTransfer(idType) {
            // if (idType.nom) {
            // 	this.idClient = idType;
            // } else {
            // 	this.idReceptive = idType
            // }
            this.tab_menue = "Email";
            this.discussion.objet = idType.objet;
            this.discussion.message = idType.message;
            this.discussion.discussion_pieces_joint =
                idType.discussion_pieces_joint;
            this.chat_discussion_id = idType.chat_discussion_id;
        },

        showMessage(message, type) {
            (this.tempMessages = message), (this.tab_menue = "Show");
            this.typeUser = type;
            this.chat_discussion_id = message.chat_discussion_id;
            this.updatestatus();
        },

        supprimer_piece_j(index) {
            this.discussion.discussion_pieces_joint.splice(index, 1);
        },

        playSound() {
            let sound =
                "http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3";
            if (sound) {
                var audio = new Audio(sound);
                audio.play();
            }
        },

        sendDiscussion(indetifiant) {
            let that = this;
            this.errors = [];
            this.success = [];
            var id = "";
            if (indetifiant == 0) {
                if (this.idReceptive == "") {
                    this.$toaster.error("Veuillez selectioner une destinateur");
                    return;
                }
            } else {
                if (this.idClient == "") {
                    this.$toaster.error("Veuillez selectioner une destinateur");
                    return;
                }
            }

            if (!this.discussion.message) {
                this.$toaster.error("Message obligatoire.");
                return;
            } else {
                this.success = [];
                if (this.model === "tab-0") {
                    this.idSend = this.idReceptive.id;
                } else {
                    this.idSend = this.idClient.id;
                }

                console.log(this.idSend);

                Axios.post(this.baseUrl + "admin/sendDiscussion", {
                    message: this.discussion.message,
                    objet: this.discussion.objet,
                    fichier: this.discussion.discussion_pieces_joint,
                    client_or_customer_id: this.idSend,
                    id_user: this.current_user.id,
                })
                    .then((response) => {
                        console.log("Raisponse", response);
                        this.listDiscussions = response.succ
                            ? response.succ
                            : this.listDiscussions;
                        this.$toaster.success("Message envoyé ");
                    })
                    .catch((error) => {
                        this.$toaster.error("Une erreur est survenue");
                        console.log("Error", error);
                    });
                this.discussion = {
                    message: "",
                    objet: "",
                    discussion_pieces_joint: [],
                };
            }
            this.getDiscussion();
            // this.getIdDiscussion();
        },

        pageChangeHandler(event) {
            this.infinite.current = event;
            let index = this.model == "tab-0" ? 0 : 1;
            this.getDiscussionForClientOrReceptive(index, this.tab_menue, 1);
        },

        getIdDiscussion(listDiscussion) {
            let d = listDiscussion.discussion;
            this.discussionprivee = d.slice().reverse();
            this.chat_discussion_id = listDiscussion.id;
            this.admin_id = listDiscussion.user_id;
            Axios.get(
                this.baseUrl +
                    "admin/discussion/" +
                    this.chat_discussion_id +
                    ""
            )
                .then(function (data) {
                    document
                        .getElementsByClassName("msg_history")[0]
                        .scrollBy(0, 99999999999999);
                })
                .catch(function (error) {
                    console.log(error);
                });
            this.updatestatus();
            this.success = [];
            this.errors = [];
        },

        getDiscussionForClientOrReceptive(type, tab_menue, page = 1) {
            this.tab_menue = tab_menue;
            const data = {
                type: parseInt(type, 10) == 0 ? "customers" : "client",
                sender_id: this.current_user.id,
                search: this.searchMessage !== "" ? this.searchMessage : "",
            };
            Axios.post(
                this.baseUrl +
                    "admin/getDiscussionReceptiveOrClient?page=" +
                    page,
                data
            )
                .then((res) => {
                    if (parseInt(type, 10) == 0) {
                        this.receptive_messages = res.data.data;
                    } else {
                        this.clients_messages = res.data.data;
                    }
                    if (this.model == "tab-0") {
                        this.paginationData = this.receptive_messages;
                    } else {
                        this.paginationData = this.clients_messages;
                    }
                    this.sents = res.data.send;
                    this.not_read_receptive = res.data.receptive;
                    this.not_read_client = res.data.client;
                    this.toReceptive = res.data.dataReceptive;
                    this.toClients = res.data.dataClient;
                    this.trashs = res.data.trash;
                })
                .catch((error) => {
                    console.log("Error", error);
                });
        },

        getDiscussion() {
            Axios.get(this.baseUrl + "admin/getDiscussion").then((response) => {
                this.listDiscussions = response.data.discussion;
                this.listDiscussionL = response.data.discussionL;
                this.users =
                    response.data.discussion.client != null
                        ? response.data.discussion.client
                        : response.data.discussion.customers;
                document
                    .getElementsByClassName("msg_history")[0]
                    .scrollBy(0, 99999999999999);
            });
            this.getStatus();
        },

        includeObject(data, findData) {
            var found = false;
            for (var i = 0; i < data.length; i++) {
                if (data[i].firstname == findData) {
                    found = true;
                    break;
                }
            }
            return found;
        },

        getUser() {
            Axios.get(this.baseUrl + "admin/getUser/")
                .then((response) => {
                    this.users = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        sendNewEmail() {
            this.idType = "";
            this.idReceptive = "";
            this.tab_menue = "Email";
            this.discussion.objet = "";
            this.discussion.message = "";
            this.discussion.discussion_pieces_joint = [];
        },
        getCurrentUser() {
            Axios.get(this.baseUrl + "admin/user")
                .then((response) => {
                    this.current_user = response.data;
                    this.getDiscussionForClientOrReceptive(0, "Inbox", 1);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        updatestatus() {
            Axios.get(
                this.baseUrl +
                    "admin/updatestatus/" +
                    this.chat_discussion_id +
                    ""
            )
                .then(function (data) {})
                .catch(function (error) {
                    console.log(error);
                });
        },
        getStatus() {
            Axios.get(this.baseUrl + "admin/getStatus").then((response) => {
                this.listDiscussionLibreNonLue = response.data.discussionLibre;
                this.listDiscussionEnCourNonLue =
                    response.data.discussionEnCours;
            });
        },
        getExtension(file) {
            let imageExt = ["jpeg", "jpg", "png", "bmp", "jiff", "gif"];
            let videoExt = ["mp4", "flv", "mkv", "3gpp"];
            let ext = file.split(".")[1];
            if (imageExt.findIndex((item) => item == ext) > -1) {
                return "image";
            }
            if (videoExt.findIndex((item) => item == ext) > -1) {
                return "video";
            }

            return false;
        },
    },

    watch: {
        model(value) {
            if (value === "tab-1") {
                this.getDiscussionForClientOrReceptive(1, "", 1);
            } else {
                this.getDiscussionForClientOrReceptive(0, "", 1);
            }
            this.tab_menue = "";
        },
    },

    mounted() {
        this.getCurrentUser();
        this.getDiscussion();
        // this.scrollToTop();
        Echo.channel("discussion").listen(".DiscussionPrivee", (e) => {
            console.log("Event", e);
            if (e.message != null) {
                if (this.chat_discussion_id == e.chat_discussion) {
                    this.discussionprivee.push({
                        message: e.message,
                        sender_id: e.sender_id,
                        chat_discussion_id: e.chat_discussion,
                        file: e.terminer,
                        status: e.token_id,
                        created_at: new Date(),
                    });
                }
                if (e.sender_id != this.current_user.id) {
                    console.log("Play song");
                    this.getCurrentUser();
                    this.playSound();
                }
                this.getDiscussion();
            }
        });
    },
};
</script>
<style lang="scss" scoped>
//active color
li.nav-item.active {
    background: #007bff !important;
    color: white;
}
.dic_send {
    display: block;
}

span.position {
    position: relative;
    top: 81px;
    right: 101px;
}
.dic_send input {
    display: block;
    width: 100%;
    padding: 9px;
    border: 1px #c4c4c4 solid;
    border-radius: 5px;
}
.form-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    textarea.write_msg {
        padding: 9px;
        border: 1px #c4c4c4 solid;
        border-radius: 5px;
        margin: 0 0 10px 0;
    }
}
.ronf {
    width: 45px !important;
    height: 45px !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}
.add_piece_joint.ronf {
    background-color: #f7941d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0;
    i.fa.fa-paperclip {
        color: #fff;
    }
}
input.form-control.piece_jointe.p-0 {
    height: 30px;
}
.boutton-env {
    padding-left: 5px;
}
.boutton-env {
    padding-left: 5px;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 110px;
    margin-left: auto;
}
.piece_jointe_container {
    width: 25%;
    margin: 10px 0;
    height: 140px;
    .btn-piece {
        height: 140px;
    }
    embed {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
div#success {
    padding-top: 15px;
}

body {
    background: #eee;
}
.email-app {
    display: flex;
    flex-direction: row;
    background: #fff;
    border: 1px solid #e1e6ef;
}

.email-app nav {
    flex: 0 0 200px;
    padding: 1rem;
    border-right: 1px solid #e1e6ef;
}

.email-app nav .btn-block {
    margin-bottom: 15px;
}

.email-app nav .nav {
    flex-direction: column;
}

.email-app nav .nav .nav-item {
    position: relative;
}

.email-app nav .nav .nav-item .nav-link,
.email-app nav .nav .nav-item .navbar .dropdown-toggle,
.navbar .email-app nav .nav .nav-item .dropdown-toggle {
    color: #151b1e;
    border-bottom: 1px solid #e1e6ef;
}

.email-app nav .nav .nav-item .nav-link i,
.email-app nav .nav .nav-item .navbar .dropdown-toggle i,
.navbar .email-app nav .nav .nav-item .dropdown-toggle i {
    width: 20px;
    margin: 0 10px 0 0;
    font-size: 14px;
    text-align: center;
}

.email-app nav .nav .nav-item .nav-link .badge,
.email-app nav .nav .nav-item .navbar .dropdown-toggle .badge,
.navbar .email-app nav .nav .nav-item .dropdown-toggle .badge {
    float: right;
    margin-top: 4px;
    margin-left: 10px;
}

.email-app main {
    min-width: 0;
    flex: 1;
    padding: 1rem;
}

.email-app .inbox .toolbar {
    padding-bottom: 1rem;
    border-bottom: 1px solid #e1e6ef;
}

.email-app .inbox .messages {
    padding: 0;
    list-style: none;
}

.email-app .inbox .message {
    position: relative;
    padding: 1rem 1rem 1rem 2rem;
    cursor: pointer;
    border-bottom: 1px solid #e1e6ef;
}

.email-app .inbox .message:hover {
    background: #f9f9fa;
}

.email-app .inbox .message .actions {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
}

.email-app .inbox .message .actions .action {
    width: 2rem;
    margin-bottom: 0.5rem;
    color: #c0cadd;
    text-align: center;
}

.email-app .inbox .message a {
    color: #000;
}

.email-app .inbox .message a:hover {
    text-decoration: none;
}

.email-app .inbox .message.unread .header,
.email-app .inbox .message.unread .title {
    font-weight: bold;
}

.email-app .inbox .message .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
}

.email-app .inbox .message .header .date {
    margin-left: auto;
}

.email-app .inbox .message .title {
    margin-bottom: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.email-app .inbox .message .description {
    font-size: 12px;
}

.email-app .message .toolbar {
    padding-bottom: 1rem;
    border-bottom: 1px solid #e1e6ef;
}

.email-app .message .details .title {
    padding: 1rem 0;
    font-weight: bold;
}

.email-app .message .details .header {
    display: flex;
    padding: 1rem 0;
    margin: 1rem 0;
    border-top: 1px solid #e1e6ef;
    border-bottom: 1px solid #e1e6ef;
}

.email-app .message .details .header .avatar {
    width: 40px;
    height: 40px;
    margin-right: 1rem;
}

.email-app .message .details .header .from {
    font-size: 12px;
    color: #9faecb;
    align-self: center;
}

.email-app .message .details .header .from span {
    display: block;
    font-weight: bold;
}

.email-app .message .details .header .date {
    margin-left: auto;
}

.email-app .message .details .attachments {
    padding: 1rem 0;
    margin-bottom: 1rem;
    border-top: 3px solid #f9f9fa;
    border-bottom: 3px solid #f9f9fa;
}

.email-app .message .details .attachments .attachment {
    display: flex;
    margin: 0.5rem 0;
    font-size: 12px;
    align-self: center;
}

.email-app .message .details .attachments .attachment .badge {
    margin: 0 0.5rem;
    line-height: inherit;
}

.email-app .message .details .attachments .attachment .menu {
    margin-left: auto;
}

.email-app .message .details .attachments .attachment .menu a {
    padding: 0 0.5rem;
    font-size: 14px;
    color: #e1e6ef;
}

@media (max-width: 767px) {
    .email-app {
        flex-direction: column;
    }
    .email-app nav {
        flex: 0 0 100%;
    }
}

@media (max-width: 575px) {
    .email-app .message .header {
        flex-flow: row wrap;
    }
    .email-app .message .header .date {
        flex: 0 0 100%;
    }
}
</style>
