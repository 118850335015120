<template>
    <div>
        <v-card>
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Chercher"
                    outlined
                ></v-text-field>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="items"
                    :items-per-page="10"
                    class="elevation-1"
                    :loading="loading"
                    @click:row="editItem"
                >   
                    <template v-slot:item.url="{ item }">
                        {{ item.url ? item.url.value : 'Aucun slug' }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import Axios from 'axios';

    export default {
        name: 'continents-slug',
        props: {
            baseurl: String,
            selected: Boolean,
            is_loading: Boolean
        },

        data: () => ({
            model: 'continent',
            items: [],
            loading: false,
            search: '',
            headers: [
                { text: 'Titre', align: 'start', sortable: true, value: 'continent_name' },
                { text: 'Slug', align: 'start', sortable: false, value: 'url' },
            ]
        }),

        watch: {
            is_loading(val){
                if(val){
                    this.retrieve()
                }
            },

            selected: {
                handler(val){
                    if(val){
                        this.retrieve()
                    }
                },
                immediate: true
            }
        },

        methods: {
            editItem(item){
                this.$emit('edit', { item, model: this.model })
            },

            retrieve(){
                this.loading = true;
                Axios.get(this.baseurl + 'slug/' + this.model).then(response => {
                    this.items = response.data
                }).catch(e => {
                    console.log('error', e)
                }).finally(() => {
                    this.loading = false;
                    this.$emit('termined');
                })
            }
        }
    }
</script>