<template>
    <v-data-table
        :headers="head"
        :items="items"
        :items-per-page="10"
        class="elevation-1"
        :loading="loading"
        @click:row="visualize"
    >   
        <template v-slot:header.checking={item}>
            <v-checkbox
                class="ml-4"
                value="check"
                true-value="check"
                :false-value="null"
                :input-value="selectinused"
                @change="checkAll"
            >
            </v-checkbox>
        </template>

        <template v-slot:item.checking={item}>
            <v-checkbox
                class="ml-4"
                :value="true"
                :true-value="true"
                :false-value="false"
                :input-value="isChecked(item) ? true : false"
                v-if="isInused(item)"
                @change="checkItem($event, item)"
                @click.stop=""
            >
            </v-checkbox>
        </template>
        
        <template v-slot:item.filename={item}>
            {{ item.filename }}
        </template>

        <template v-slot:item.target={item}>
            <v-chip :color="getStatus(item.target)" dark>
                {{ item.target ? item.target.title : 'Non utilisé' }}
            </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
            <div class="d-flex">
                <v-icon
                    small
                    v-if="isInused(item)"
                    @click.stop="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>

                
            </div>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        name: 'storage-list-index',
        props: {
            isInused: Function,
            deleteItem: Function,
            getStatus: Function,
            checkItem: Function,
            isChecked: Function,
            selectinused: String,
            visualize: Function,
            loading: Boolean,
            items: Array
        },

        data(){
            return {
                head: [
                    { text: '', align: 'center', value: 'checking', width: '30px', sortable: false },
                    { text: 'Fichier', align: 'start', value: 'filename' },
                    { text: this.labelName, align: 'start', value: 'target' },
                    { text: 'Action', align: 'start', value: 'actions' }
                ],
            }
        },

        methods: {
            checkAll(event){
                this.$emit('checkall', event)
            }
        }
    }
</script>