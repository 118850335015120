<template>
    <v-data-table
        :headers="head"
        :items="files"
        :items-per-page="10"
        class="elevation-1"
        :loading="loading"
        @click:row="visualize"
    >   
        <template v-slot:header.checking={item}>
            <v-checkbox
                class="ml-4"
                value="check"
                true-value="check"
                :false-value="null"
                :input-value="selectall"
                @change="checkAll"
            >
            </v-checkbox>
        </template>

        <template v-slot:item.checking={item}>
            <v-checkbox
                class="ml-4"
                :value="true"
                :true-value="true"
                :false-value="false"
                :input-value="isChecked(item) ? true : false"
                @change="checkItem($event, item)"
                @click.stop=""
            >
            </v-checkbox>
        </template>

        <template v-slot:item.filename={item}>
            {{ item.filename }}
        </template>

        <template v-slot:item.actions="{ item }">
            <div class="d-flex">
                <v-icon
                    title="Supprimer"
                    @click.stop="actionItem('delete', item)"
                >
                    mdi-delete
                </v-icon>

                <v-icon
                    class="ml-2"
                    title="Restaurer"
                    @click.stop="actionItem('restore', item)"
                >
                    mdi-file-restore
                </v-icon>
            </div>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        name: 'storage-list-archive',
        props: {
            actionItem: Function,
            checkItem: Function,
            selectall: String,
            visualize: Function,
            loading: Boolean,
            files: Array,
            isChecked: Function
        },

        data(){
            return {
                head: [
                    { text: '', align: 'center', value: 'checking', width: '30px', sortable: false },
                    { text: 'Fichier', align: 'start', value: 'filename' },
                    { text: 'Action', align: 'start', value: 'actions' }
                ],
            }
        },

        methods: {
            checkAll(event){
                this.$emit('checkall', event)
            }
        }
    }
</script>