<template>
    <div>
        <v-card flat>
            <v-card-title>
                Appliquer les pourcentages <span v-if="!save" class="red--text text-caption ml-2">(Les modifications ne sont pas enregistrées, les valeurs appliquées seront celles enregistrées)</span>
            </v-card-title>

            <v-card-text>
                <v-form @submit.prevent="applicate">
                    <v-row>
                        <v-col cols="3">
                            <v-select
                                :items="items"
                                item-text="label"
                                item-value="value"
                                label="Appliquer pour"
                                outlined
                                dense
                                v-model="model.selected"
                                clearable
                            ></v-select>
                        </v-col>

                        <v-col cols="9">
                            <v-select
                                v-if="is_circuit"
                                :items="circuits"
                                item-text="title_circuits"
                                item-value="id"
                                label="Choisir les circuits"
                                outlined
                                dense
                                v-model="model.circuit"
                                clearable
                                multiple
                                :loading="loading"
                                :rules="rules"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-btn
                        depressed
                        color="primary"
                        class="ml-4"
                        type="submit"
                        v-if="applicable"
                    >
                        Appliquer
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import Axios from 'axios';

    export default {
        name: 'application-percentage',
        props: {
            percent: Object,
            save: Boolean
        },

        data: () => ({
            baseUrl: window.baseUrl + 'admin/',
            model: {
                selected: null,
                circuit: null
            },
            items: [
                { label: 'Tous', value: 'tous' },
                { label: 'Les circuits séléctionnés', value: 'circuit' }
            ],
            circuits: [],
            loading: false,

            rules: [
                (v) =>  (v && v.length > 0) || "Sélectionnez au moins un circuit"
            ]
        }),

        watch: {
            'model.selected': {
                handler(val){
                    if(val && val == 'circuit' && this.circuits.length == 0){
                        this.getAllCircuit()
                    }
                }
            },
        },

        computed: {
            is_circuit(){
                return (this.model.selected && this.model.selected == 'circuit') ? true : false
            },

            applicable(){
                if(this.model.selected){
                    if(this.model.selected == 'circuit'){
                        if(this.model.circuit && this.model.circuit.length > 0){
                            return true
                        }else{
                            return false
                        }
                    }else{
                        return true
                    }
                }

                return false
            }
        },

        methods: {
            getAllCircuit(){
                this.loading = true
                Axios.get(this.baseUrl + 'circuits_back/actif/get_all').then(response => {
                    this.circuits = response.data
                }).finally(() => {
                    this.loading = false
                })
            },

            applicate(){
                this.$emit('loading', true)

                let datas = new FormData();

                datas.append('selected', this.model.selected)
                datas.append('circuits', this.model.circuit)

                Axios.post(this.baseUrl + 'percentages/circuits/applique', datas).then(response => {
                    if(response.data.success){
                        this.$toaster.success(response.data.success);
                    }

                    if(response.data.error){
                        this.$toaster.error("Une erreur est survenue, veuillez réessayer");
                        console.log('error', response.data.error)
                    }
                }).catch(error => {
                    this.$toaster.error("Une erreur est survenue, veuillez réessayer");
                    console.log('error', error)
                }).finally(() => {
                    this.$emit('loading', false)
                })
            }
        }
    }
</script>